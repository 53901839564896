import { ChakraComponent, chakra } from '@plugsurfing/plugsurfing-design';
import CdButton from 'components/design-elements/CdButton';
import CdFormControlBox from 'components/design-elements/CdFormControlBox';
import { FormikProps } from 'formik';
import { memo, ComponentProps, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type CdFormWrapperProps = ComponentProps<ChakraComponent<'form', object>>;

const FormWrapper = (props: CdFormWrapperProps) => (
  // For Formik to receive onSubmit event regardless of whether there's error or not, noValidate is set to true here.
  // This also prevents HTML5's default validation error popup on each field.
  <chakra.form noValidate display="flex" gap="component.s" flexDirection="column" {...props} />
);

export default FormWrapper;

export const CdFormWrapperWithControls = memo(
  <T,>({
    children,
    isSubmitting,
    isValid,
    handleSubmit,
    onCancel,
    confirmButtonText,
  }: FormikProps<T> & { children: ReactNode; onCancel?: () => void; confirmButtonText?: string }) => {
    const { t } = useTranslation();

    return (
      <FormWrapper onSubmit={handleSubmit}>
        {children}
        <CdFormControlBox>
          {onCancel !== undefined && (
            <CdButton tertiary onClick={onCancel}>
              {t('cancel')}
            </CdButton>
          )}
          <CdButton primary type="submit" isLoading={isSubmitting} isDisabled={isSubmitting || !isValid}>
            {confirmButtonText || t('save')}
          </CdButton>
        </CdFormControlBox>
      </FormWrapper>
    );
  },
);
