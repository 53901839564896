import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Role, SystemUser } from '@plugsurfing/cdm-api-client';
import * as Sentry from '@sentry/react';
import { MFA_NAME } from 'config/constants';
import { t } from 'i18n';
import { SignInResultType } from 'models/cognito';
import authSlice, { AuthState, AuthStatus, ChallengeType, State } from 'redux/slices/auth';
import { fetchSelf, fetchUserRoles } from 'redux/users/actions';
import CDMServiceV2 from 'services/CDMServiceV2';
import { findMessage } from 'utils/formatters';
import { getCognitoUsername } from 'utils/helpers';
import Logger from 'utils/log';
import Analytics from 'utils/meta/analytics';
import { AppDispatch } from '../redux';

type Device = {
  DeviceAttributes: {
    Name: string;
    Value: string;
  }[];
  DeviceCreateDate: number;
  DeviceKey: string;
  DeviceLastAuthenticatedDate: number;
  DeviceLastModifiedDate: number;
};

type DeviceInfo = {
  Device: Device;
};

class AuthService {
  async setupTOTPAuth({ temporaryUser }: State): Promise<string> {
    if (!temporaryUser) {
      throw new TypeError('User is not defined');
    }

    const userEmail = await this.getAttributes(temporaryUser, 'email');
    if (!userEmail) {
      throw new TypeError('Email attribute not found');
    }

    try {
      const secretCode = await Auth.setupTOTP(temporaryUser);
      const qrCode = `otpauth://totp/${MFA_NAME}:${userEmail}?secret=${secretCode}&issuer=Plugsurfing`;
      return qrCode;
    } catch (e) {
      Logger.error(findMessage(e));
      throw e;
    }
  }

  async confirmSignInTOTP(
    { state, temporaryUser }: State,
    dispatch: AppDispatch,
    token: string,
    hasUserSetupMFA: boolean,
    trustDevice: boolean,
  ) {
    if (state.status !== AuthStatus.Challenged) {
      throw new TypeError(`Expected status to be ${AuthStatus.Challenged}, but it was ${state.status}`);
    }

    if (state.challenge.type !== ChallengeType.MFA) {
      throw new TypeError(`Expected type to be ${ChallengeType.MFA}, but it was ${state.challenge.type}`);
    }

    const { challenge } = state;
    try {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.CompletingChallenge }));

      if (hasUserSetupMFA) {
        await Auth.confirmSignIn(temporaryUser, token, SignInResultType.MFA);
      } else {
        await Auth.verifyTotpToken(temporaryUser, token);
        await Auth.setPreferredMFA(temporaryUser, 'TOTP');
      }

      if (trustDevice) {
        await this.rememberDevice();
      }

      await Promise.all([this._fetchTingcoreUserOrSignOut(temporaryUser, dispatch), this.getRoles(dispatch)]);
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedIn }));
    } catch (error) {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.Challenged, challenge, completionError: error }));
      throw error;
    }
  }

  async signIn(email: string, password: string, { state }: State, dispatch: AppDispatch) {
    if (state.status !== AuthStatus.SignedOut) {
      Logger.error('The auth service is in an invalid state, try to refresh the browser.');
      return;
    }
    try {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SigningIn }));
      const signInResult = await Auth.signIn(getCognitoUsername(email), password);
      dispatch(authSlice.actions.updateTemporaryUser(signInResult));
      dispatch(authSlice.actions.updateState(await this._processSignInResult(signInResult, dispatch)));
    } catch (error) {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedOut, signInError: error }));
      throw error;
    }
  }

  async completeNewPasswordChallenge(
    newPassword: string,
    requiredAttributeData: Partial<any> | undefined,
    { state, temporaryUser }: State,
    dispatch: AppDispatch,
  ) {
    if (state.status !== AuthStatus.Challenged) {
      throw new TypeError(`Expected status to be ${AuthStatus.Challenged}, but it was ${state.status}`);
    }

    if (state.challenge.type !== ChallengeType.NewPasswordRequired) {
      throw new TypeError(
        `Expected type to be ${ChallengeType.NewPasswordRequired}, but it was ${state.challenge.type}`,
      );
    }

    const { challenge } = state;
    try {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.CompletingChallenge }));
      const signInResult = await Auth.completeNewPassword(temporaryUser, newPassword, requiredAttributeData);
      dispatch(authSlice.actions.updateState(await this._processSignInResult(signInResult, dispatch)));
    } catch (error) {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.Challenged, challenge, completionError: error }));
    }
  }

  async signOut({ state }: State, dispatch: AppDispatch) {
    if (state.status !== AuthStatus.SignedIn) {
      throw new Error(`Expected status to be ${AuthStatus.SignedIn}, but it was ${state.status}`);
    }

    try {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SigningOut }));
      await Auth.signOut({ global: true });
    } catch (error) {
      Logger.warn(findMessage(error));
    } finally {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedOut }));
    }
  }

  async restoreSession({ state }: State, dispatch: AppDispatch) {
    if (state.status !== AuthStatus.SignedOut) {
      return;
    }

    try {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.RestoringSession }));
      const awsUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const hasEnforceMFA = await this.enforceMFAAttribute(awsUser);

      if (awsUser.preferredMFA === SignInResultType.MFA || !hasEnforceMFA) {
        await Promise.all([this._fetchTingcoreUserOrSignOut(awsUser, dispatch), this.getRoles(dispatch)]);
        dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedIn }));
      } else {
        dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedOut }));
        await Auth.signOut({ global: true });
      }
    } catch (error) {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedOut }));
      Logger.warn(findMessage(error));
    }
  }

  async forgotPassword(email: string, dispatch: AppDispatch) {
    try {
      const cognitoUsername = getCognitoUsername(email);
      await Auth.forgotPassword(cognitoUsername);
      dispatch(authSlice.actions.updateState({ status: AuthStatus.RequestingPasswordReset, cognitoUsername }));
    } catch (e) {
      Logger.error(findMessage(e));
      throw e;
    }
  }

  async confirmForgotPassword(confirmationCode: string, newPassword: string, { state }: State, dispatch: AppDispatch) {
    if (state.status !== AuthStatus.RequestingPasswordReset) {
      throw new Error(`Expected status to be ${AuthStatus.RequestingPasswordReset}, but it was ${state.status}`);
    }
    try {
      await Auth.forgotPasswordSubmit(state.cognitoUsername, newPassword, confirmationCode);
    } catch (e) {
      const message = findMessage(e);

      Logger.error(message);

      const formattedMessage =
        message?.includes('Invalid') && message.includes('verification')
          ? t('invalidVerificationCode')
          : t('somethingWentWrong');

      throw new Error(formattedMessage);
    }
    dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedOut }));
  }

  private getAttributes(user: CognitoUser, name: string) {
    return new Promise<string | undefined>((resolve, rejected) => {
      user.getUserAttributes((err, attr) => {
        if (err) {
          rejected(err);
        } else {
          resolve(attr?.find(attr => attr.Name === name)?.Value);
        }
      });
    });
  }

  private async getDevice() {
    let currUser: CognitoUser;

    try {
      currUser = await Auth.currentUserPoolUser();
    } catch (error) {
      Logger.warn(error);
      throw error;
    }

    currUser.getCachedDeviceKeyAndPassword();
    return new Promise<any>((resolve, rejected) => {
      currUser.getDevice({
        onSuccess: resolve,
        onFailure: rejected,
      });
    });
  }

  private getDeviceStatus() {
    return this.getDevice()
      .then((device: DeviceInfo) => {
        const isValid =
          device.Device.DeviceAttributes.find(attribute => attribute.Name === 'device_status')?.Value === 'valid';
        return isValid;
      })
      .catch(err => {
        Logger.warn(err);
        return false;
      });
  }

  private async enforceMFAAttribute(user: CognitoUser): Promise<boolean> {
    const enforceMFA = await this.getAttributes(user, 'custom:enforceMFA');

    if (enforceMFA) {
      return !!+enforceMFA;
    }

    return false;
  }

  private async rememberDevice() {
    try {
      await Auth.rememberDevice();
    } catch (err) {
      Logger.error(err);
      Sentry.captureException(err);
    }
  }

  private async forgetDevice() {
    try {
      await Auth.forgetDevice();
    } catch (err) {
      Logger.error(err);
      Sentry.captureException(err);
    }
  }

  private async getPreferredMFAType(user: CognitoUser): Promise<string | undefined> {
    try {
      return await Auth.getPreferredMFA(user, { bypassCache: true });
    } catch (err) {
      Logger.error(err);
      Sentry.captureException(err);
    }
  }

  private async _processSignInResult(awsUser: any, dispatch: AppDispatch): Promise<AuthState> {
    if (
      awsUser.challengeName !== SignInResultType.MFA &&
      awsUser.challengeName !== SignInResultType.NewPasswordRequired
    ) {
      const hasEnforceMFA = await this.enforceMFAAttribute(awsUser);
      const isDeviceRemembered = await this.getDeviceStatus();
      const preferredMFA = await this.getPreferredMFAType(awsUser);

      if (preferredMFA !== SignInResultType.MFA && isDeviceRemembered && hasEnforceMFA) {
        await this.forgetDevice();

        return {
          status: AuthStatus.Challenged,
          challenge: { type: ChallengeType.MFA, challengeParameters: awsUser.challengeParam },
        };
      } else if (!isDeviceRemembered && hasEnforceMFA) {
        return {
          status: AuthStatus.Challenged,
          challenge: { type: ChallengeType.MFA, challengeParameters: awsUser.challengeParam },
        };
      }
    }

    switch (awsUser.challengeName) {
      case SignInResultType.MFA: {
        const { challengeParam } = awsUser;
        return {
          status: AuthStatus.Challenged,
          challenge: { type: ChallengeType.MFA, challengeParameters: challengeParam },
        };
      }

      case SignInResultType.NewPasswordRequired: {
        const { userAttributes, requiredAttributes } = awsUser.challengeParam;
        return {
          status: AuthStatus.Challenged,
          challenge: { type: ChallengeType.NewPasswordRequired, userAttributes, requiredAttributes },
        };
      }

      case SignInResultType.CustomChallenge: {
        const { challengeParam } = awsUser;
        return {
          status: AuthStatus.Challenged,
          challenge: { type: ChallengeType.CustomChallenge, challengeParameters: challengeParam },
        };
      }

      default: {
        await Promise.all([this._fetchTingcoreUserOrSignOut(awsUser, dispatch), this.getRoles(dispatch)]);

        return { status: AuthStatus.SignedIn };
      }
    }
  }

  private async _fetchTingcoreUserOrSignOut(awsUser: any, dispatch: AppDispatch): Promise<SystemUser> {
    try {
      return await this.getSelf(dispatch);
    } catch (error) {
      await awsUser.signOut();
      throw error;
    }
  }

  private async getSelf(dispatch: AppDispatch): Promise<SystemUser> {
    const result = await CDMServiceV2.usersClientV2.getSelfUsingGET();
    const { organization } = result;

    Analytics.setUserProperties({
      user_properties: {
        organization_name: organization.name,
      },
    });

    dispatch(fetchSelf(result));
    return result;
  }

  private async getRoles(dispatch: AppDispatch): Promise<Role[]> {
    const result = await CDMServiceV2.rolesClient.getRolesUsingGET();
    dispatch(fetchUserRoles.done({ result, params: undefined }));
    return result;
  }
}

export default new AuthService();
