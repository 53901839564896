import { Box, Button, Flex } from '@plugsurfing/plugsurfing-design';
import { CdMeta } from 'components/design-elements/CdMeta/CdMeta';
import { LocalesKey } from 'i18n';
import { p3Theme } from 'styles/theme';

interface ErrorHandlingViewProps {
  src: string;
  title: LocalesKey;
  heading: string;
  message: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
}

const ErrorHandlingView = ({
  src,
  title,
  heading,
  message,
  primaryButtonText,
  onPrimaryButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
}: ErrorHandlingViewProps) => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <CdMeta titleKey={title} />
      <Flex direction="column" justifyContent="center" alignItems="center" maxW="500px" textAlign="center">
        <Box h="215px" width="215px" dangerouslySetInnerHTML={{ __html: src }} />
        <Box {...p3Theme.textStyles.heading.xs} mt="component.2xl" mb="component.s">
          {heading}
        </Box>
        <Box {...p3Theme.textStyles.paragraph.s} color={p3Theme.colors.text.secondary}>
          {message}
        </Box>
        <Button variant="primary" onClick={onPrimaryButtonClick} mt="component.2xl">
          {primaryButtonText}
        </Button>
        {secondaryButtonText && onSecondaryButtonClick && (
          <Button variant="compactAccent" onClick={onSecondaryButtonClick} mt="component.m">
            {secondaryButtonText}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ErrorHandlingView;
