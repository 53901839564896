import { SystemUser } from '@plugsurfing/cdm-api-client';
import { Avatar, Button, Flex, Icon, IconButton } from '@plugsurfing/plugsurfing-design';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetOrganizationImagesUsingGetQuery } from 'cdm-api-client/v2OrganizationsApi';
import { ROOT } from 'config/links';
import { forwardRef, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { defaultLogotype } from 'styles/theme';
import { getImage, getLogo } from 'utils/views';
import { LayoutContainerContext } from 'views/LayoutContainer';
import styles from './CdTopBar.module.scss';

export interface CdTopBarProps {
  user?: SystemUser;
}

const CdTopBar = forwardRef<HTMLDivElement, CdTopBarProps>(({ user }, ref) => {
  const { data: organizationImages, isLoading } = useGetOrganizationImagesUsingGetQuery(
    user ? { organizationId: user.organization.id } : skipToken,
  );
  const logo = getLogo(organizationImages?.config.images);
  const { showVisible, hideVisible, visible, setUserMenuVisible, userMenuVisible } = useContext(LayoutContainerContext);

  const image = useMemo(() => {
    return (logo && getImage(logo)) || defaultLogotype;
  }, [organizationImages]);

  return (
    <Flex
      bg="background.primary"
      ref={ref}
      as={'nav'}
      h="64px"
      alignItems="center"
      borderBottomColor="border.primary"
      borderBottomWidth="1px"
      position="sticky"
      top={0}
      zIndex={100}
    >
      <Flex flex="1" py="component.s" pl="component.xs" pr="component.3xs" justifyContent={'space-between'}>
        <Flex>
          <IconButton
            variant="quiet"
            icon={visible ? 'Close' : 'Menu'}
            aria-label="open navigation"
            mr="component.s"
            onClick={() => {
              if (visible) {
                hideVisible();
              } else {
                showVisible();
              }
              setUserMenuVisible(false);
            }}
          />
          <Link
            to={ROOT().path}
            onClick={() => {
              hideVisible();
              setUserMenuVisible(false);
            }}
          >
            {isLoading ? null : <img src={image} className={styles.imageLogotype} />}
          </Link>
        </Flex>
        <Button
          variant="quiet"
          onClick={() => {
            setUserMenuVisible(!userMenuVisible);
            showVisible();
          }}
        >
          <Avatar
            w="icons.xl"
            h="icons.xl"
            borderRadius="l"
            color="interactive.primary"
            bg="interactive.background"
            name={user?.firstName || ''}
            mr="component.3xs"
          />
          <Icon size="m" name={userMenuVisible ? 'ChevronUp' : 'ChevronDown'} />
        </Button>
      </Flex>
    </Flex>
  );
});

export default CdTopBar;
