import { OrgRegistrationOrderSearchRequest, PagingCursor } from '@plugsurfing/cdm-api-client';
import CDMService from './CDMServiceV2';

export default {
  ...CDMService.organizationOrdersClient,
  search: (
    request: { organizationId: string } & OrgRegistrationOrderSearchRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ) => {
    const { orderStatus, organizationName, organizationId } = request;

    return CDMService.organizationOrdersClient.searchOrdersUsingPOST(
      { organizationName, orderStatus },
      organizationId,
      count,
      cursor?.sortField,
      cursor?.sortFieldCursor as { [key: string]: string },
      cursor?.sortFieldSortOrder,
      cursor?.idField,
      cursor?.idFieldCursor,
      cursor?.idFieldSortOrder,
      fetchPrevious,
    );
  },
};
