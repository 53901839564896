import { Box, Button, Flex, SectionMessage, Text } from '@plugsurfing/plugsurfing-design';
import { CdButton, CdField, CdFormWrapper } from 'components/design-elements';
import { CHANGE_PASSWORD, SIGNIN } from 'config/links';
import { Formik, FormikActions } from 'formik';
import { t } from 'i18n';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InjectedProps, connect, useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { AuthStatus, selectAuthError, selectAuthState, selectAuthStatus } from 'redux/slices/auth';
import { createStructuredSelector } from 'reselect';
import AuthService from 'services/AuthService';
import { p3Theme } from 'styles/theme';
import { findMessage } from 'utils/formatters';
import { isValidCognitoPassword, notEmptyString, notValidEmailAddress, pickErrors } from 'utils/forms';
import { InputField } from 'utils/forms/renders';
import Logger from 'utils/log';
import { CdToastProps, withCdToast } from 'utils/toast';
import LogoHeading from 'views/auth/LogoHeading';
import styles from './SignInView.module.scss';

const withRedux = connect(
  createStructuredSelector({
    currentError: selectAuthError,
    authStatus: selectAuthStatus,
  }),
);

interface FormDataEmail {
  email: string;
}

const initialValuesEmail: FormDataEmail = {
  email: '',
};

function validateEmail(data: FormDataEmail) {
  return pickErrors({ email: notValidEmailAddress(data.email) });
}

const RequestForgotPasswordView = memo((props: CdToastProps & InjectedProps<typeof withRedux>) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async ({ email }: FormDataEmail, { setSubmitting }: FormikActions<FormDataEmail>) => {
      try {
        setSubmitting(true);
        await AuthService.forgotPassword(email, dispatch);
      } catch (error) {
        Logger.error('Something went wrong when calling auth service', error);
        props.toast.error(translate('somethingWentWrong'));
      } finally {
        setSubmitting(false);
      }
    },
    [props.toast, translate],
  );

  if (props.authStatus === AuthStatus.RequestingPasswordReset) {
    return <Redirect to={CHANGE_PASSWORD().path} />;
  }

  return (
    <div className={styles.container}>
      <Box
        width={['100vw', '100wv', '460px']}
        boxShadow={['none', 'none', 'lg']}
        borderRadius={['none', 'none', 'm']}
        borderWidth={p3Theme.borderWidths.s}
        borderStyle="solid"
        borderColor={['transparent', 'transparent', 'border.primary']}
        padding="component.xs"
      >
        <Formik
          initialValues={initialValuesEmail}
          validate={validateEmail}
          onSubmit={handleSubmit}
          render={({ isSubmitting, handleSubmit }) => (
            <CdFormWrapper onSubmit={handleSubmit}>
              <Flex padding={['component.xs', 'component.xs', 'component.l']} direction="column" gap="component.s">
                <Box pb="component.s">
                  <LogoHeading>{translate('loginResetPasswordHeader')}</LogoHeading>
                  <CdField
                    name="email"
                    type="email"
                    label={translate('email')}
                    autoComplete="email"
                    placeholder={translate('loginEmailPlaceholder')}
                    isDisabled={isSubmitting}
                    component={InputField}
                  />
                  {props.currentError !== undefined && (
                    <SectionMessage
                      status="error"
                      description={findMessage(props.currentError) ?? translate('somethingWentWrong')}
                    />
                  )}
                </Box>
                <CdButton w="100%" primary type="submit" size="S" isDisabled={isSubmitting} isLoading={isSubmitting}>
                  {translate('loginResetPasswordHeader')}
                </CdButton>
              </Flex>
            </CdFormWrapper>
          )}
        />
      </Box>
    </div>
  );
});

interface FormDataPassword {
  password: string;
  confirmPassword: string;
  confirmationCode: string;
}

const initialValuesPassword: FormDataPassword = {
  password: '',
  confirmPassword: '',
  confirmationCode: '',
};

function validatePassowrd(data: FormDataPassword) {
  return pickErrors({
    password: isValidCognitoPassword(data.password),
    confirmPassword:
      data.password !== data.confirmPassword ? t('validatePasswordsEqual') : notEmptyString(data.confirmPassword),
    confirmationCode: notEmptyString(data.confirmationCode.trim()),
  });
}

const ForgotPasswordViewInner = memo((props: CdToastProps & InjectedProps<typeof withRedux>) => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const [resetSuccess, setResetSuccess] = useState<boolean>(false);
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);

  const handleSubmit = useCallback(
    async ({ password, confirmationCode }: FormDataPassword, { setSubmitting }: FormikActions<FormDataPassword>) => {
      try {
        setSubmitting(true);
        await AuthService.confirmForgotPassword(password, confirmationCode.trim(), authState, dispatch);
        setResetSuccess(true);
      } catch (error) {
        Logger.error('Something went wrong when calling auth service', error);
        props.toast.error(findMessage(error) ?? translate('somethingWentWrong'));
      } finally {
        setSubmitting(false);
      }
    },
    [authState, dispatch, props.toast, translate],
  );

  return (
    <div className={styles.container}>
      <Box
        width={['100vw', '100wv', '460px']}
        boxShadow={['none', 'none', 'lg']}
        borderRadius={['none', 'none', 'm']}
        borderWidth={p3Theme.borderWidths.s}
        borderStyle="solid"
        borderColor={['transparent', 'transparent', 'border.primary']}
        padding="component.xs"
      >
        <Formik
          initialValues={initialValuesPassword}
          validate={validatePassowrd}
          onSubmit={handleSubmit}
          render={({ isSubmitting, handleSubmit, submitCount }) => (
            <CdFormWrapper onSubmit={handleSubmit}>
              <Box padding={['component.xs', 'component.xs', 'component.l']}>
                <LogoHeading>{translate('loginChangePasswordHeader')}</LogoHeading>
                <Flex rowGap="component.l" direction="column">
                  {resetSuccess ? (
                    <>
                      <Text>{translate('resetPasswordSuccessDescription')}</Text>
                      <Button variant="primary" onClick={() => history.push(SIGNIN().path)}>
                        {translate('goToSignIn')}
                      </Button>
                    </>
                  ) : (
                    <>
                      <CdField
                        name="confirmationCode"
                        label={translate('confirmationCode')}
                        autoComplete="one-time-code"
                        placeholder={translate('resetConfirmationCodePlaceholder')}
                        isDisabled={isSubmitting}
                        component={InputField}
                        suppressError={submitCount === 0}
                      />
                      <CdField
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        label={translate('newPassword')}
                        placeholder={translate('resetNewPasswordPlaceholder')}
                        isDisabled={isSubmitting}
                        component={InputField}
                        suppressError={submitCount === 0}
                      />
                      <CdField
                        name="confirmPassword"
                        type="password"
                        autoComplete="new-password"
                        label={translate('confirmPassword')}
                        placeholder={translate('resetConfirmPasswordPlaceholder')}
                        isDisabled={isSubmitting}
                        component={InputField}
                        suppressError={submitCount === 0}
                      />
                      {props.currentError !== undefined && (
                        <SectionMessage
                          mt="component.s"
                          status="error"
                          description={findMessage(props.currentError) ?? translate('somethingWentWrong')}
                        />
                      )}
                      <CdButton
                        primary
                        type="submit"
                        size="S"
                        isDisabled={isSubmitting}
                        isLoading={isSubmitting}
                        w="100%"
                      >
                        {translate('changePassword')}
                      </CdButton>
                    </>
                  )}
                </Flex>
              </Box>
            </CdFormWrapper>
          )}
        />
      </Box>
    </div>
  );
});

export const ForgotPasswordView = withRedux(withCdToast(ForgotPasswordViewInner));

export default withRedux(withCdToast(RequestForgotPasswordView));
