import { CompleteChargePointModel, Currency, GeoCoordinate, GraceRuleConfiguration } from '@plugsurfing/cdm-api-client';
import * as Sentry from '@sentry/react';
import { POWER_STORAGE_FACTOR } from 'config/constants';
import { LocalesKey, getLanguage, t } from 'i18n';
import isFinite from 'lodash/isFinite';
import { DateTime } from 'luxon';
import { CDMError } from 'services/CDMError';
import { NUMBER_OF_DECIMALS } from 'utils/views/map';

export * from './enums';

export const formatGeoCoordinate = (geoCoordinate: GeoCoordinate) =>
  `Latitude ${Number(geoCoordinate.latitude).toFixed(NUMBER_OF_DECIMALS)}, Longitude ${Number(
    geoCoordinate.longitude,
  ).toFixed(NUMBER_OF_DECIMALS)}`;

export const formatPower = (powerInWatts: number, unit?: string): string => {
  if (unit === 'kWh') {
    return `${Number(powerInWatts / POWER_STORAGE_FACTOR).toFixed(1)} ${unit}`;
  } else {
    if (powerInWatts > 1000) {
      return `${Math.round(powerInWatts / POWER_STORAGE_FACTOR)} kW`;
    } else {
      return `${powerInWatts} W`;
    }
  }
};

export const formatVoltage = (voltageInVolts: number) => `${voltageInVolts} V`;

export const formatCurrent = (currentInAmperes: number) => `${currentInAmperes} A`;

export const formatPrice = (price: number | string, currency: string) => `${price} ${currency}`;

export const formatPriceWithCurrency = (price: number, currency: string, short?: boolean) =>
  new Intl.NumberFormat(getLanguage(), {
    style: 'currency',
    currency,
    ...(short
      ? {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }
      : {}),
  }).format(price);

export const formatConnectorCount = (model: CompleteChargePointModel): string => {
  const counts = model.chargePointModelConfigEntities.map(x => x.data.connectorConfigs.length);
  const minCount = Math.min(...counts);
  const maxCount = Math.max(...counts);

  if (isFinite(minCount) && isFinite(maxCount)) {
    return minCount === maxCount ? `${minCount}` : `${minCount}-${maxCount}`;
  } else {
    return '?';
  }
};

export const formatCountry = (countryIsoCode: string, name?: string) =>
  t(countryIsoCode as LocalesKey, name && { defaultValue: name });

export const formatLanguage = (countryCode: string): string => t(`lang-${countryCode}` as LocalesKey);

export const formatCurrencyOption = (currency: Currency) => {
  const currencySign = new Intl.NumberFormat(window.navigator.language, { style: 'currency', currency: currency.code })
    .formatToParts(1)
    .find(x => x.type === 'currency');
  return `${currencySign?.value} - ${currency.name}`;
};

/**
 * Date & time formats used in P3
 *
 * Before adding new variants, please consider if it is really needed!
 * Notation reference: https://moment.github.io/luxon/#/formatting
 *
 * TODO: Simplify variants
 */
export type DateFormat =
  // ======================= Example ================= Usage purposes (as-is) ========

  // ----------------------- Month ---------------------------------------------------
  | 'LLL yyyy' //            Jan 2024                  Chart tooltips

  // ----------------------- Week number ---------------------------------------------
  | 'yyyy #W' //             2024 #1                   Chart tooltips

  // ----------------------- Date ----------------------------------------------------
  | 'yyyy-MM-dd' //          2024-01-03                General purpose, table cells
  | 'dd LLL yyyy' //         03 Jan 2024               General purpose
  | 'DD' //                  Jan 3, 2024               Chart tooltips

  // ----------------------- Date & time ---------------------------------------------
  | 'yyyy-MM-dd HH:mm' //    2024-01-01 15:34          Technical information
  | 'dd-MM-yyyy - HH:mm' //  03-01-2024 - 15:34        General purpose
  | 'dd-MM-yyyy hh:mm' //    03-01-2024 03:34          General purpose
  | 'dd LLL yyyy HH:mm' //   03 Jan 2024 15:34         General purpose
  | 'HH:mm, dd LLL yyyy' //  15:34, 03 Jan 2024        General purpose
  | 'hh:mm yyyy-MM-dd' //    03:34 2024-01-03          General purpose
  | 'DD HH:mm' //            Jan 3, 2024 15:34         Chart tooltips
  | 'yyyyMMdd_HHmm' //       20240103_1534             Downloaded file name suffix

  // ----------------------- Precise date & time -------------------------------------
  | 'HH:mm:ss yyyy-MM-dd' // 15:34:56 2024-01-03
  | 'yyyy-MM-dd HH:mm:ss' // 2024-01-03 15:34:56       Technical information
  | 'ISO' //                 2024-01-03T15:34:56.000Z  Technical information

  // ----------------------- Time ----------------------------------------------------
  | 'HH:mm' //               15:34                     General purpose

  // ----------------------- Precise time --------------------------------------------
  | 'HH:mm:ss'; //           15:34:56                  General purpose

/**
 * Format date as a string
 *
 * @param date Date, timestamp or ISO string to be formatted
 * @param format One of pre-defined formats to be used
 * @param utc If set to true, formats into a UTC date. Default is false and local zone is used.
 * @returns Formatted string. If provided date is invalid, returns empty string.
 */
export const formatDateTime = (
  date: Date | number | string,
  format: DateFormat = 'yyyy-MM-dd',
  utc = false,
): string => {
  let dateTime = DateTime.fromJSDate(new Date(date));

  if (!dateTime.isValid) {
    return '';
  }

  if (utc) {
    dateTime = dateTime.toUTC();
  }

  return format === 'ISO' ? dateTime.toISO() : dateTime.setLocale(getLanguage()).toFormat(format);
};

export const graceRuleOperatorTranslation = (operator: GraceRuleConfiguration.OperatorEnum) => {
  if (GraceRuleConfiguration.OperatorEnum.OR === operator) {
    return t('or');
  } else if (GraceRuleConfiguration.OperatorEnum.AND === operator) {
    return t('and');
  }
  return operator;
};

export const formatGracePeriod = (graceRuleConfiguration: GraceRuleConfiguration) =>
  `${graceRuleConfiguration.duration / 60} ${t('minuteOrMinutes')} ${graceRuleOperatorTranslation(
    graceRuleConfiguration.operator,
  )} ${formatPower(graceRuleConfiguration.energy, 'kWh')}`;

export const abbreviateNumber = (value: number): string => {
  // eslint-disable-next-line no-bitwise
  const tier = (Math.log10(Math.abs(value)) / 3) | 0;

  if (tier === 0) {
    return String(value);
  }

  const symbol = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
  const suffix = symbol[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = value / scale;

  return scaled.toFixed(1) + suffix;
};

export const formatAmountInMinorUnits = (amountInMinorUnits: number, currency?: string) =>
  `${(amountInMinorUnits / 100).toLocaleString()}${currency ? ` ${currency}` : ''}`;

export function findMessage(obj: unknown): string | undefined {
  if (obj instanceof CDMError) {
    switch (obj.type) {
      // Here, handle known error types for returning translated error message based on `type` and `parameters`.
      // See https://plugsurfing.atlassian.net/wiki/spaces/LA005382/pages/2406416472/Error+responses+across+our+BE+services+and+towards+FE+ones

      default:
        if (typeof obj.message === 'string' && obj.message.trim() !== '') {
          return obj.message;
        }

        Sentry.captureException(new Error(`Unknown CDMError of type '${obj.type ?? ''}'`), {
          level: 'warning',
          extra: {
            parameters: obj.parameters,
            details: obj.details,
            statusCode: obj.statusCode,
            code: obj.code,
            fieldViolations: obj.fieldViolations,
            message: obj.message,
          },
        });

        return undefined;
    }
  }

  return typeof obj === 'object' && obj !== null && 'message' in obj && typeof obj.message === 'string'
    ? obj.message
    : undefined;
}

export const toCapitalize = (text: string) => {
  const customtext = text.toLocaleLowerCase();
  return customtext.charAt(0).toUpperCase() + customtext.slice(1);
};
