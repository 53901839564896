import {
  ChargingKeyBulkUpdateRequest,
  ChargingKeyBulkUpdateResponse,
  PageResponseChargingKey,
  PageResponseChargingKeyWithDebt,
  PagingCursor,
} from '@plugsurfing/cdm-api-client';
import { EntityOwnerType } from 'augmentations';
import CDMService from './CDMServiceV2';

export interface ChargingKeysSearchRequest {
  email?: string;
  keyIdentifier?: string;
  referenceId?: string;
  licensePlate?: string;
  organizationId?: string;
  contractId?: string;
}
export interface ChargingKeysOwnerSearchRequest extends ChargingKeysSearchRequest {
  chargingKeyOwnerId: string;
  chargingKeyOwnerType: EntityOwnerType;
}

export default {
  ...CDMService.chargingKeysClient,
  ...CDMService.customerKeysOrdersClient,
  searchByOwner: (
    request: ChargingKeysOwnerSearchRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ): Promise<PageResponseChargingKeyWithDebt> => {
    const { chargingKeyOwnerId, chargingKeyOwnerType, email, keyIdentifier, referenceId, licensePlate } = request;
    return CDMService.chargingKeysClient.getByOwnerIdAndTypeUsingGET(
      chargingKeyOwnerId,
      chargingKeyOwnerType,
      fetchPrevious,
      count,
      cursor?.sortField,
      // @ts-ignore
      cursor?.sortFieldCursor,
      cursor?.sortFieldSortOrder,
      cursor?.idField,
      cursor?.idFieldCursor,
      cursor?.idFieldSortOrder,
      keyIdentifier,
      email,
      referenceId,
      licensePlate,
    );
  },
  search: (
    request: ChargingKeysSearchRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ): Promise<PageResponseChargingKey> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };

    const { email, keyIdentifier, referenceId, licensePlate, organizationId, contractId } = request;
    return CDMService.chargingKeysClient.getAllChargingKeysUsingGET(
      fetchPrevious,
      count,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
      keyIdentifier,
      contractId,
      email,
      referenceId,
      licensePlate,
      organizationId,
    );
  },

  bulkUpdateChargingKeys: (request: Partial<ChargingKeyBulkUpdateRequest>): Promise<ChargingKeyBulkUpdateResponse> =>
    CDMService.chargingKeysClient.bulkUpdateChargingKeysUsingPUT(request as any),
};
