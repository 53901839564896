/* eslint-disable */
export let SignInResultType;
(function () {
  SignInResultType = {
    Authenticated: 'AUTHENTICATED',
    MFA: 'SOFTWARE_TOKEN_MFA',
    NewPasswordRequired: 'NEW_PASSWORD_REQUIRED',
    CustomChallenge: 'CUSTOM_CHALLENGE',
  };
})();

export let CognitoErrorCode;
(function () {
  CognitoErrorCode = {
    AliasExists: 'AliasExistsException',
    CodeDeliveryFailure: 'CodeDeliveryFailureException',
    CodeMismatch: 'CodeMismatchException',
    EnableSoftwareTokenMFA: 'EnableSoftwareTokenMFAException',
    ConcurrentModification: 'ConcurrentModificationException',
    ExpiredCode: 'ExpiredCodeException',
    GroupExists: 'GroupExistsException',
    InternalError: 'InternalErrorException',
    InvalidEmailRoleAccessPolicy: 'InvalidEmailRoleAccessPolicyException',
    InvalidLambdaResponse: 'InvalidLambdaResponseException',
    InvalidOAuthFlow: 'InvalidOAuthFlowException',
    InvalidParameter: 'InvalidParameterException',
    InvalidPassword: 'InvalidPasswordException',
    InvalidSmsRoleAccessPolicy: 'InvalidSmsRoleAccessPolicyException',
    InvalidSmsRoleTrustRelationship: 'InvalidSmsRoleTrustRelationshipException',
    InvalidUserPoolConfiguration: 'InvalidUserPoolConfigurationException',
    LimitExceeded: 'LimitExceededException',
    MFAMethodNotFound: 'MFAMethodNotFoundException',
    NotAuthorized: 'NotAuthorizedException',
    PasswordResetRequired: 'PasswordResetRequiredException',
    ResourceNotFound: 'ResourceNotFoundException',
    ScopeDoesNotExist: 'ScopeDoesNotExistException',
    SoftwareTokenMFANotFound: 'SoftwareTokenMFANotFoundException',
    TooManyFailedAttempts: 'TooManyFailedAttemptsException',
    TooManyRequests: 'TooManyRequestsException',
    UnexpectedLambda: 'UnexpectedLambdaException',
    UserLambdaValidation: 'UserLambdaValidationException',
    UserNotConfirmed: 'UserNotConfirmedException',
    UserNotFound: 'UserNotFoundException',
    UsernameExists: 'UsernameExistsException',
  };
})();
