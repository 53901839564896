import { ConnectionProfileEntity } from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export interface State {
  byOrgId: {
    [key: string]: string[];
  };
  byId: {
    [key: string]: ConnectionProfileEntity;
  };
}

export const initialState: State = {
  byOrgId: {},
  byId: {},
};

export default function (state: State = initialState, action: Action): State {
  return produce(state, draft => {
    if (isType(action, actions.remove.done)) {
      const { params } = action.payload;
      const { [params]: toRemove, ...rest } = state.byId;
      draft.byId = rest;
    }

    if (isType(action, actions.fetch.done)) {
      const { result } = action.payload;
      draft.byId[result.metadata.id] = result;
    }

    if (isType(action, actions.fetchByOrganization.done)) {
      const { result, params } = action.payload;
      result.forEach(connectionProfile => {
        draft.byId[connectionProfile.metadata.id] = connectionProfile;
        if (params) {
          draft.byOrgId[params] = draft.byOrgId[params] || [];
          draft.byOrgId[params].push(connectionProfile.metadata.id);
        }
      });
    }
  });
}
