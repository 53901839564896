import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuProps,
} from '@plugsurfing/plugsurfing-design';
import { MouseEvent, useCallback } from 'react';
import { p3Theme } from 'styles/theme';

const { colors } = p3Theme;

export interface CdContextMenuItem {
  text: string;
  onClick: () => void;
  variant?: 'primary' | 'secondary' | 'danger';
}

export interface CdContextMenuProps extends Omit<MenuProps, 'children'> {
  menuItems: CdContextMenuItem[];
  className?: string;
  buttonProps?: Partial<IconButtonProps>;
}

const getTextColor = (variant?: 'primary' | 'secondary' | 'danger') => {
  switch (variant) {
    case 'secondary':
      return colors.text.secondary;
    case 'danger':
      return colors.interactive.danger;
    default:
      return colors.text.primary;
  }
};

export const CdContextMenu = ({ buttonProps, menuItems, className = '', ...props }: CdContextMenuProps) => {
  const onButtonClick = buttonProps?.onClick;
  const handleButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      // Stop propagation so that parent element does not respond to click events
      e.stopPropagation();
      onButtonClick?.(e);
    },
    [onButtonClick],
  );

  return (
    <Menu strategy="fixed" {...props}>
      <MenuButton
        as={IconButton}
        icon="Kebab"
        aria-label="Context menu"
        size="XS"
        variant="quiet"
        {...buttonProps}
        onClick={handleButtonClick}
      />
      <MenuList className={className}>
        {menuItems.map((item, i) => (
          <MenuItem
            key={item.text + String(i)}
            textTransform="capitalize"
            onClick={e => {
              e.stopPropagation();
              item.onClick();
            }}
            color={getTextColor(item.variant)}
          >
            {item.text}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default CdContextMenu;
