import { format } from '@fast-csv/format';
import { Customer, SystemUser } from '@plugsurfing/cdm-api-client';
import { ChargingKeyOrderResponse, GenericAddress } from 'cdm-api-client/v1CustomerKeyOrdersApi';
import { BASENAME, STRIPE_URL_PREFIX_BILLING, STRIPE_URL_PREFIX_PAYG } from 'config/constants';
import { saveAs } from 'file-saver';
import { History, createBrowserHistory } from 'history';
import { LocalesKey, t } from 'i18n';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';
import { CognitoErrorCode } from 'models/cognito';
import { Organization } from 'models/organization';
import { useCallback, useMemo, useState, type ChangeEvent } from 'react';
import { Observable } from 'rxjs';
import { map, reduce, share, takeUntil } from 'rxjs/operators';
import { Readable } from 'stream';
import { formatDateTime } from 'utils/formatters';
import { useUnmount } from 'utils/hooks/useUnmount';
import { useCdToast } from 'utils/toast';

export const UserStatus = { ...Customer.StatusEnum, ...SystemUser.StatusEnum };

export const CET = 'CET';

export const yesNoOrEmpty = (value?: boolean) => {
  if (isUndefined(value)) {
    return '';
  }
  return value ? t('yes') : t('no');
};

export function delay(ms: number) {
  return new Promise<void>(resolve => {
    setTimeout(resolve, ms);
  });
}

// Maybe a bad idea?
export function tryParse(value: string) {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

// Wrap a function so it returns the same promise while it's resolving
// Useful for making sure we don't make identical requests in parallel
export function memoizeAsync<Args extends any[], T, K>(
  fn: (...args: Args) => T | PromiseLike<T>,
  computeCacheKey: (...args: Args) => K,
) {
  const cache = new Map<K, Promise<T>>();
  return (...args: Args): Promise<T> => {
    const cacheKey = computeCacheKey(...args);

    if (!cache.has(cacheKey)) {
      const promise = (async () => {
        try {
          return await fn(...args);
        } finally {
          cache.delete(cacheKey);
        }
      })();
      cache.set(cacheKey, promise);
    }
    return cache.get(cacheKey)!;
  };
}

export function ensureSlash(pathname = '') {
  const hasSlash = pathname[pathname.length - 1] === '/';
  return hasSlash ? pathname : `${pathname}/`;
}

export function createObject<K1, K2 extends string, V>(
  keys: ReadonlyArray<K1>,
  createEntry: (key: K1, index: number) => [K2, V],
): Record<K2, V> {
  return keys.reduce(
    (obj, key, index) => {
      const [newKey, value] = createEntry(key, index);
      obj[newKey] = value;
      return obj;
    },
    {} as Record<K2, V>,
  );
}

export function mapValues<Key extends string, Value, NewValue>(
  object: Record<Key, Value>,
  mapper: (value: Value, key: Key) => NewValue,
) {
  const newObject = {} as any;
  // eslint-disable-next-line guard-for-in
  for (const key in object) {
    newObject[key] = mapper(object[key], key);
  }
  return newObject as Record<Key, NewValue>;
}

export function identity<T>(item: T): T {
  return item;
}

/**
 * Returns a higly likely unique string id based on the current time and a random number
 */
export function createId(): string {
  return `${Date.now()}${Math.random()}`;
}

/**
 * Like Partial, but recursively and deeply so.
 * It doesn't propagate types properly to arrays, so you'll have to take care of that manually.
 * Here's an example:
 *
 * ```
 * interface MyStuffItem { id: string, omitted: string }
 * interface MyStuff { arr: MyStuffItem[], omitted: string }
 * const myStuff: DeepPartial<MyStuff> = {
 *   arr: [{
 *     id: 'a7b3e98cf0',
 *   }] as MyStuffItem[],
 * }
 * ```
 *
 * (Credit for the array solution: https://stackoverflow.com/a/19457036/2463028)
 */

export function stringify(obj: any) {
  return JSON.stringify(obj, null, 2);
}

export function getCognitoUsername(email: string) {
  return email.toLowerCase();
}

export const firstOrUndefined = <T>(arr?: T[]): T | undefined => (arr && arr.length > 0 ? arr[0] : undefined);

// Object.assign that ignores values if they are undefined.
// https://stackoverflow.com/a/39514270/2124745
export function assignDefined(target: { [x: string]: any }, ...sources: any[]) {
  for (const source of sources) {
    for (const key of Object.keys(source)) {
      const val = source[key];
      if (val !== undefined) {
        target[key] = val;
      }
    }
  }
  return target;
}

export const undefinedIfEmpty = (s: string) => (s.trim() !== '' ? s : undefined);

// The forms return ids for
export const replaceEmptyIdsWithUndefined = (obj: { [x: string]: any; hasOwnProperty?: any; id?: any } | undefined) => {
  if (obj === undefined || typeof obj === 'number' || typeof obj === 'string') {
    return obj;
  }
  if (Object.prototype.hasOwnProperty.call(obj, 'id')) {
    if (obj.id === '') {
      obj.id = undefined;
    }
  }
  Object.keys(obj).forEach(key => {
    obj[key] = replaceEmptyIdsWithUndefined(obj[key]);
  });
  return obj;
};

interface UploadFileConfig {
  url: string;
  method: 'PUT' | 'POST';
  file: File;
  onProgress?: (percentDone: number) => any;
  debugLog?: (...stuff: any[]) => any;
}

export function uploadFile({ url, method, file, onProgress = noop, debugLog = noop }: UploadFileConfig): Promise<any> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener('progress', e => {
      if (e.lengthComputable) {
        onProgress((100 * e.loaded) / e.total);
      } else {
        debugLog('Progress not computable', e);
      }
    });

    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          debugLog('upload successful');
          resolve(undefined);
        } else {
          debugLog('upload failed', xhr.responseText);
          reject(xhr.responseText);
        }
      }
    });

    xhr.upload.addEventListener('abort', reject);
    xhr.upload.addEventListener('error', reject);

    xhr.open(method, url);
    xhr.overrideMimeType(file.type);

    xhr.send(file);
  });
}

export const isDefined = (obj?: boolean | null | undefined): boolean => obj !== undefined && obj !== null;

interface Option {
  text: string;
}

export function alphabetizeSelectOptions<T extends Option>(options: T[]): T[] {
  return options.sort((a: T, b: T) =>
    a.text.toLowerCase() > b.text.toLowerCase() ? 1 : b.text.toLowerCase() > a.text.toLowerCase() ? -1 : 0,
  );
}

export function getLocalizedCognitoError({ code }: Error & { code?: CognitoErrorCode }) {
  switch (code) {
    case CognitoErrorCode.NotAuthorized:
    case CognitoErrorCode.UserNotFound:
      return t('errorNotAuthorized');
    default:
      return t('authErrorMessage');
  }
}

const createNameElement = (name: string, companyName = ''): HTMLElement => {
  const p = document.createElement('p');
  const companyNode = companyName.trim() === '' ? undefined : document.createTextNode(companyName);
  const nameNode = document.createTextNode(name);

  p.classList.add('bold');

  if (companyNode !== undefined) {
    p.appendChild(companyNode);
    p.appendChild(document.createElement('br'));
  }

  p.appendChild(nameNode);

  return p;
};

const createAddressElement = (addressParam: GenericAddress) => {
  const { name, address, addressLine2, postalCode, city, country, firstName, lastName, companyName } = addressParam;
  const renderName = name || name === '' ? name : `${firstName} ${lastName}`;
  const wrapper = document.createElement('div');
  const elements = [address, addressLine2, [postalCode, city].join(' '), country].filter(
    text => (text ?? '').trim() !== '',
  );

  wrapper.className = 'address';
  wrapper.appendChild(createNameElement(renderName, companyName));

  for (const text of elements) {
    const node = document.createElement('p');

    node.textContent = text;

    wrapper.appendChild(node);
  }

  return wrapper;
};

const createStyleElement = () => {
  const styleElement = document.createElement('style');
  const printStyles = `
    @page {
      size: A4;
      margin: 0cm !important;
    }
    .address {
      display: inline-block;
      width: 33.3333%;
      height: 12.5%;
      text-align: center;
      font-size: 12pt;
    }
    .bold {
      font-weight: 600;
    }
    p {
      margin: 0;
    }
    `;
  styleElement.innerText = printStyles;
  return styleElement;
};

export const printAddresses = (addresses: GenericAddress[]) => {
  const ifr = document.createElement('iframe');
  ifr.src = 'about:blank';
  ifr.setAttribute('sandbox', 'allow-same-origin allow-modals');
  ifr.addEventListener(
    'load',
    () => {
      const content = ifr.contentDocument!;
      addresses.map(address => {
        content.body.appendChild(createAddressElement(address));
      });
      content.head.appendChild(createStyleElement());
    },
    false,
  );

  document.body.appendChild(ifr);
  ifr.contentWindow!.print();
  setTimeout(() => {
    document.body.removeChild(ifr);
  }, 1000);
};

export const useSaveProductOrderAsCsvForDP = (errorMsg?: LocalesKey) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const unmount$ = useUnmount();
  const toast = useCdToast();
  const excelUtf = '\uFEFF';

  const saveAsCsv = useCallback(
    (orders: ChargingKeyOrderResponse[]) => {
      setIsProcessing(true);
      const stream = format();
      const saveObservable = fromStream<string>(stream);

      saveObservable
        .pipe(
          reduce(
            (acc, data) => {
              acc.push(data);
              return acc;
            },
            [excelUtf] as string[],
          ),
          map(chunks => new Blob(chunks, { type: 'data:text/csv;charset=utf-8' })),
          takeUntil(unmount$),
        )
        .subscribe(
          blob => {
            const date = formatDateTime(new Date(), 'yyyyMMdd_HHmm').replace(/,\s/g, '_');
            saveAs(blob, `product-orders-${date}.csv`);
            setIsProcessing(false);
          },
          () => {
            setIsProcessing(false);
            if (errorMsg) {
              toast.warn(t(errorMsg));
            }
          },
        );
      try {
        stream.write([
          'Order ID',
          'Order date',
          'Order quantity',
          'Payment status',
          'Product type',
          'Organisation name',
          'Delivery address',
          'Customer email',
          'Country',
          'Language',
        ]);
        orders.forEach(order => {
          const { id, created, quantity, productType, organizationName, customerEmail, language, state } = order;
          const { name, address, addressLine2, postalCode, city, country, firstName, lastName } = order.address ?? {};
          /**
           * This might seem a bit odd, but UCC does our shipment of Product orders they need this csv export.
           * The address needs to be in this very particular format as in order to process it with the Deutsche Post
           * online shop (First NAME Last NAME;EXTRAINFO;STREET;STREET_NUMBER;ZIP;CITY;COUNTRY;HOUSE)
           */
          const deliveryAddress = [
            name || name === '' ? name : `${firstName} ${lastName}`,
            '', // Extra not existing in our data
            (address ?? '') + (addressLine2 ? ` ${addressLine2}` : ''),
            '', // Street number is included in address
            postalCode,
            city,
            country,
            'HOUSE', // HOUSE is required from Deutsche Post
          ]
            .map(a => a?.trim?.())
            .join(';');
          stream.write([
            id.trim(),
            formatDateTime(created, 'yyyy-MM-dd'),
            quantity,
            state,
            productType?.trim() ?? '',
            organizationName?.trim() ?? '',
            deliveryAddress,
            customerEmail?.trim() ?? '',
            country?.trim() ?? '',
            language?.trim()?.toUpperCase() || '',
          ]);
        });
      } catch (e) {
        setIsProcessing(false);
        stream.emit('error', e);
        console.error(e);
      } finally {
        stream.end();
      }
    },
    [errorMsg],
  );

  return useMemo(
    () => ({
      isProcessing,
      saveAsCsv,
    }),
    [isProcessing],
  );
};

function fromStream<T>(stream: Readable, finishEventName = 'end', dataEventName = 'data'): Observable<T> {
  stream.pause();

  return new Observable<T>(rxObserver => {
    function dataHandler(data: T | undefined) {
      rxObserver.next(data);
    }

    function errorHandler(err: any) {
      rxObserver.error(err);
    }

    function endHandler() {
      rxObserver.complete();
    }

    stream.addListener(dataEventName, dataHandler);
    stream.addListener('error', errorHandler);
    stream.addListener(finishEventName, endHandler);

    stream.resume();

    return () => {
      stream.removeListener(dataEventName, dataHandler);
      stream.removeListener('error', errorHandler);
      stream.removeListener(finishEventName, endHandler);
    };
  }).pipe(share());
}

const createCardStyleElement = () => {
  const styleElement = document.createElement('style');
  const printStyles = `
    @page {
      size: A4;
      margin: 0cm !important;
    }
    body{
     display: grid;
     margin: 0;
     padding-top: 2px;
     grid-template-columns: repeat(3, 7cm);
     grid-auto-rows: 37mm;
    }
    .address {
        text-align: center;
        overflow: hidden;
        font-size: 12pt;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .bold {
      font-weight: 600;
    }
     p {
      margin: 0;
    }
    `;
  styleElement.innerText = printStyles;
  return styleElement;
};

export const printAddressesInCards = (addresses: GenericAddress[]) => {
  const ifr = document.createElement('iframe');
  ifr.src = 'about:blank';
  ifr.setAttribute('sandbox', 'allow-same-origin allow-modals');
  ifr.addEventListener(
    'load',
    () => {
      const content = ifr.contentDocument!;
      addresses.map(address => {
        content.body.appendChild(createAddressElement(address));
      });
      content.head.appendChild(createCardStyleElement());
    },
    false,
  );

  document.body.appendChild(ifr);
  ifr.contentWindow!.print();
  setTimeout(() => {
    document.body.removeChild(ifr);
  }, 1000);
};

export let history: History = createBrowserHistory({ basename: BASENAME });

export function setHistory(newHistory: History) {
  history = newHistory;
}

export const getDynamicLinkObject = (obj: { name?: string; id: string }) => ({
  id: obj.id,
  name: obj.name ? obj.name : '',
});

const isActiveItemAtIndex = (item: { linkPath: string }, index: number) => {
  const location = history.location;
  const locationSplit = location.pathname.split('/');
  const linkPathSplit = item.linkPath.split('/');

  if (locationSplit.length >= 2 && linkPathSplit.length >= 2) {
    return locationSplit[index] === linkPathSplit[index];
  }
  return false;
};

export const isActiveItem = (item: { linkPath: string }) => isActiveItemAtIndex(item, 1);

export const isActiveSubItem = (item: { linkPath: string }) => isActiveItemAtIndex(item, 2);

export const parseWithMaxStringLength = (v: string | number, max: number | undefined) => String(v).substring(0, max);

export const returnTrimmedStringOnPasteEvent = (
  event: ChangeEvent<HTMLInputElement> | MouseEvent,
  inputValue?: string,
) => {
  // @ts-ignore
  // Ignored due to miss of inputType type in React
  // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react/index.d.ts
  const isPasted = event.nativeEvent?.inputType?.startsWith('insertFromPaste');
  return isPasted ? inputValue?.trim() : inputValue;
};

export const organizationStringifier = (val: Organization[]) =>
  val.length > 0 ? JSON.stringify(val.map(({ id, name }) => ({ id, name }))) : '';

export const organizationParser = (val: string) => JSON.parse(val);

export const arrayParser = (val: string) => JSON.parse(val);

export const arrayStringifier = (val: string | any[]) => (val.length > 0 ? JSON.stringify(val) : val);

export const formatUserStatus = (status: Customer.StatusEnum | SystemUser.StatusEnum): string | undefined => {
  switch (status) {
    case 'ACTIVE':
      return t('active');
    case 'DELETION_FAILED_DEBT':
      return t('debtAsBlocker');
    case 'OTHER_DELETION_ERROR':
      return t('deletionFailed');
    case 'PENDING_DELETION':
      return t('pendingDeletion');
  }
};

export const getVariantFromUserStatus = (
  status: Customer.StatusEnum | SystemUser.StatusEnum,
): 'errorInverse' | 'warningInverse' | 'infoInverse' | undefined => {
  switch (status) {
    case 'DELETION_FAILED_DEBT':
      return 'warningInverse';
    case 'OTHER_DELETION_ERROR':
      return 'errorInverse';
    case 'PENDING_DELETION':
      return 'infoInverse';
    default:
      return undefined;
  }
};

export const createQueryString = (params: { [key: string]: any[] | string }) => {
  const paramsKeys = Object.keys(params);
  const query = paramsKeys
    .map((key, index) => {
      const paramValue = Array.isArray(params[key]) ? JSON.stringify(params[key]) : params[key];
      if (index === 0) {
        return `?${key}=${paramValue}`;
      }
      return `&${key}=${paramValue}`;
    })
    .join('');
  return query;
};

// Code created with the help of Stack Overflow question
// https://stackoverflow.com/a/105074/2124745
export const UUID = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}-${s4()}`;
};

export function safeParseJson<T = unknown>(json: string): T | undefined {
  try {
    return JSON.parse(json) as T;
  } catch (e) {
    return undefined;
  }
}

export const getStripeAccountLink = (type: 'BILLING' | 'PAYG', id: string) => {
  if (type === 'PAYG') {
    return `${STRIPE_URL_PREFIX_PAYG}/${id}`;
  }
  if (type === 'BILLING') {
    return `${STRIPE_URL_PREFIX_BILLING}/${id}`;
  }
};

export function getId<T extends { id: string }>(model: T): string {
  return model.id;
}

export function getName<T extends { name: string }>(model: T): string {
  return model.name;
}
