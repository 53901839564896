import CdButton from 'components/design-elements/CdButton';
import CdContextMenu, { CdContextMenuItem } from 'components/design-elements/CdContextMenu';
import upperFirst from 'lodash/upperFirst';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSelf, selectUserRoles } from 'redux/users/selectors';
import { canUserAccess, type PrivilegeCheck } from 'utils/roles';
import styles from './styles.module.scss';

export type CdTableHoverActionItem = CdContextMenuItem & PrivilegeCheck;

interface CdTableHoverActionsProps {
  isSticky: boolean;
  menuItems: CdTableHoverActionItem[];
}

export default function CdTableHoverActions({ menuItems, isSticky }: CdTableHoverActionsProps) {
  const self = useSelector(selectSelf);
  const allRoles = useSelector(selectUserRoles());
  const upperMenuItems = useMemo(
    () =>
      menuItems
        .filter(item => canUserAccess(self, allRoles.data, item.allowedPrivileges, item.allowedModules))
        .map(item => ({ ...item, text: upperFirst(item.text) })),
    [allRoles.data, menuItems, self],
  );

  if (upperMenuItems.length === 0) {
    return null;
  }

  if (!isSticky) {
    return <CdContextMenu menuItems={upperMenuItems} buttonProps={{ skeletonAsIcon: true }} />;
  }

  const [head, ...rest] = upperMenuItems;

  return (
    <div className={styles.hoverActionsContainer}>
      <CdButton primary size="XS" onClick={head.onClick}>
        {head.text}
      </CdButton>
      {rest.length > 0 && <CdContextMenu menuItems={rest} buttonProps={{ skeletonAsIcon: true }} />}
    </div>
  );
}
