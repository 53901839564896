import { AlertStatus, ToastId, ToastOptions, useToast, UseToastOptions } from '@plugsurfing/plugsurfing-design';
import { ComponentType, CSSProperties, ReactNode, useMemo } from 'react';
import { p3Theme } from 'styles/theme';

export interface CdToast extends Pick<ReturnType<typeof useToast>, 'close'> {
  info: (message: ReactNode, options?: Partial<UseToastOptions>) => ToastId | undefined;
  warn: (message: ReactNode, options?: Partial<UseToastOptions>) => ToastId | undefined;
  error: (message: ReactNode, options?: Partial<UseToastOptions>) => ToastId | undefined;
  success: (message: ReactNode, options?: Partial<UseToastOptions>) => ToastId | undefined;
}

const MILLISECONDS_BEFORE_AUTO_CLOSE = 10 * 1000;

const defaultOptions: UseToastOptions = {
  isClosable: true,
  duration: MILLISECONDS_BEFORE_AUTO_CLOSE,
  position: 'top' as const,
  containerStyle: {
    ...p3Theme.textStyles.paragraph.m,
    fontWeight: p3Theme.textStyles.paragraph.m.fontWeight as CSSProperties['fontWeight'],
  },
};

const emptyOptions: UseToastOptions = {};

export function useCdToast(): CdToast {
  const toast = useToast(emptyOptions);

  return useMemo(() => {
    const createHandler =
      (status: Exclude<AlertStatus, 'loading'>) =>
      (description: ReactNode, options?: Partial<Omit<ToastOptions, 'containerStyle'>>) =>
        toast({
          ...defaultOptions,
          ...options,
          status,
          description,
        });

    return {
      close: (id: ToastId) => toast.close(id),
      info: createHandler('info'),
      warn: createHandler('warning'),
      error: createHandler('error'),
      success: createHandler('success'),
    };
  }, [toast]);
}

export interface CdToastProps {
  toast: CdToast;
}

export function withCdToast<P extends object>(Cmp: ComponentType<P & CdToastProps>): ComponentType<P> {
  return (props: P) => {
    const toast = useCdToast();

    return <Cmp {...props} toast={toast} />;
  };
}
