import { useMergeRefs } from '@plugsurfing/plugsurfing-design';
import { SubComponentRenderer } from 'components/design-elements/CdTable/CdTable';
import CdTableSubComponentContainerRow from 'components/design-elements/CdTable/anatomy/CdTableSubComponentContainerRow';
import { ExtraColumnOptions } from 'components/design-elements/CdTable/anatomy/useColumns';
import { RowClickAction, useRowClickHandler } from 'components/design-elements/CdTable/anatomy/useRowClickHandler';
import { ForwardedRef, PropsWithRef, RefObject, forwardRef, memo, useRef } from 'react';
import { IdType, Row as TableRowInterface } from 'react-table';
import { getStickyColumnProps } from './helpers';
import styles from './styles.module.scss';

export type RowClickActionFactory<T extends object> = (rowModel: T, rowId: IdType<T>) => RowClickAction;

interface TableRowProps<T extends object> {
  columnCount: number;
  stickyColumnCount: number;
  row: TableRowInterface<T & { __isSubRow?: boolean }>;
  parent?: TableRowInterface<T & { __isSubRow?: boolean }>;
  SubComponent?: SubComponentRenderer<T>;
  extraColumnOptions: Map<IdType<T>, ExtraColumnOptions>;
  /**
   * Pass down whatever that should trigger re-render.
   * Since row is mutable and doesn't necessarily reflect changes, you might want to pass down specific field of it.
   *
   * See: https://github.com/TanStack/table/issues/1496
   */
  trackUpdate?: unknown;
  shouldApplySkeleton: boolean;
  clickAction?: RowClickActionFactory<T>;
  containerRef: RefObject<HTMLElement>;
  tableRef: RefObject<HTMLTableElement>;
}

function TableRow<T extends object>(
  {
    columnCount,
    row,
    parent,
    extraColumnOptions,
    stickyColumnCount,
    SubComponent,
    clickAction,
    shouldApplySkeleton,
    containerRef,
    tableRef,
  }: TableRowProps<T>,
  forwardedRef: ForwardedRef<HTMLTableRowElement>,
) {
  const ref = useRef<HTMLTableRowElement>(null);
  const handleClickRow = useRowClickHandler(ref, row, clickAction?.(row.original, row.id));
  const mergedRef = useMergeRefs(ref, forwardedRef);

  if (row.original.__isSubRow) {
    if (SubComponent === undefined || shouldApplySkeleton || parent === undefined) {
      return null;
    }

    return (
      <CdTableSubComponentContainerRow
        ref={mergedRef}
        parent={parent}
        row={row}
        SubComponent={SubComponent}
        tableRef={tableRef}
        containerRef={containerRef}
        stickyColumnCount={stickyColumnCount}
        columnCount={columnCount}
      />
    );
  }

  // For better performance in rendering large tables, we don't use PDL/Chakra UI's `Tr` and `Td` element here.
  return (
    <tr
      className={[
        styles.row,
        shouldApplySkeleton ? styles.loading : '',
        handleClickRow === undefined ? '' : styles.clickable,
      ].join(' ')}
      ref={mergedRef}
      onClick={handleClickRow}
      {...row.getRowProps()}
      aria-selected={row.isSelected ? 'true' : undefined}
      tabIndex={0}
    >
      {row.cells.map((cell, index) => {
        const { key, ...props } = cell.getCellProps();
        const extraOptions = extraColumnOptions.get(cell.column.id);
        const stickyAttrs = getStickyColumnProps(index, stickyColumnCount);

        return (
          <td
            {...props}
            key={key}
            {...stickyAttrs}
            {...extraOptions?.tdProps}
            className={[
              styles.cell,
              stickyAttrs.className ?? '',
              extraOptions?.tdProps?.className ?? '',
              extraOptions?.isActionCell ? styles.action : '',
              row.isExpanded ? styles.expanded : '',
            ].join(' ')}
            style={{ textAlign: extraOptions?.textAlign, ...stickyAttrs.style, ...extraOptions?.tdProps?.style }}
          >
            {cell.render('Cell')}
          </td>
        );
      })}
    </tr>
  );
}

export const CdTableRow = memo(forwardRef(TableRow)) as <T extends object>(
  props: PropsWithRef<TableRowProps<T> & { ref: RefObject<HTMLTableRowElement> }>,
) => JSX.Element;
