import { Icon, IconProps, Tooltip, TooltipProps } from '@plugsurfing/plugsurfing-design';
import { memo, ReactNode } from 'react';
import { p3Theme } from 'styles/theme';

export interface CdTooltipProps extends Omit<TooltipProps, 'children'> {
  /**
   * If not provided, an "info" icon will be shown by default.
   */
  children?: ReactNode;
  /**
   * Props passed to the default "info" icon. If `children` is provided, this prop has no effect.
   */
  iconProps?: Partial<IconProps>;
}

export default memo(({ children, iconProps, ...rest }: CdTooltipProps) => {
  const trigger = children ?? <Icon size="s" name="Info" color={p3Theme.colors.icon.secondary} {...iconProps} />;

  return (
    <Tooltip placement="top" {...rest}>
      {trigger}
    </Tooltip>
  );
});
