import { ChargingKeyType, ChargingKeyWithDebt } from '@plugsurfing/cdm-api-client';
import { Owner } from 'models/CRMApiV2Models';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const fetchTypes = actionCreator.async<void, ChargingKeyType[], Error>('FETCH_KEY_TYPES');

export const fetch = actionCreator.async<string, ChargingKeyWithDebt, Error>('FETCH_CHARGING_KEY');

export const fetchByOrganization = actionCreator.async<string, ChargingKeyWithDebt[], Error>(
  'FETCH_CHARGING_KEYS_BY_ORGANIZATION',
);

export const fetchByUser = actionCreator.async<string, ChargingKeyWithDebt[], Error>('FETCH_CHARGING_KEYS_BY_USER');

export const deleteChargingKey = actionCreator.async<{ owner: Owner; id: string }, Response, Error>(
  'DELETE_CHARGING_KEY',
);
