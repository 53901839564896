import { EntityOwnerType } from 'augmentations';
import { combineEpics } from 'redux-observable';
import { mergeMapEpic } from 'redux/utils';
import ChargingKeysService from 'services/ChargingKeyService';
import * as actions from './actions';

export default combineEpics(
  mergeMapEpic({
    asyncAction: actions.fetchTypes,
    api: () => ChargingKeysService.getChargingKeyTypesUsingGET(),
    filter: (_, state) => Object.values(state.value.chargingKeys.types.byId).length === 0,
  }),
  mergeMapEpic({
    asyncAction: actions.fetch,
    api: action => ChargingKeysService.getChargingKeyByIdUsingGET(action.payload),
  }),
  mergeMapEpic({
    asyncAction: actions.fetchByOrganization,
    api: action =>
      ChargingKeysService.getByOwnerIdAndTypeUsingGET(action.payload, EntityOwnerType.ORGANIZATION).then(
        res => res.content,
      ),
    filter: (action, state) => state.value.chargingKeys.keys.byOrgId[action.payload] === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchByUser,
    api: action =>
      ChargingKeysService.getByOwnerIdAndTypeUsingGET(action.payload, EntityOwnerType.USER).then(res => res.content),
    filter: (action, state) => state.value.chargingKeys.keys.byUserId[action.payload] === undefined,
  }),
);
