import Link from 'models/Link';
import {
  AUTHRESULTREAD,
  BOTENANNA,
  CHARGEPOINTCONNECTIONPROFILECREATE,
  CHARGEPOINTCONNECTIONPROFILEDELETE,
  CHARGEPOINTFIRMWARECREATE,
  EVMODELSREAD,
  EXTERNALROAMINGINTEGRATIONSCREATE,
  EXTERNALROAMINGINTEGRATIONSREAD,
  EXTERNALROAMINGINTEGRATIONSUPDATE,
  MANAGEPLATFORM,
  PARTNERCREATE,
  PARTNERREAD,
  PRICEASSOCIATIONBULKWRITE,
  PRICEPROFILEBULKWRITE,
  SMARTCHARGINGREAD,
  TAXATIONREAD,
  TAXATIONWRITE,
} from 'utils/roles';
import { DynamicLinkProps, ROOT } from './common';

export const ADMIN = () =>
  Link.static({
    nameLocaleKey: 'admin',
    pathPart: 'admin',
    parent: ROOT(),
    allowedPrivileges: [
      CHARGEPOINTFIRMWARECREATE,
      CHARGEPOINTCONNECTIONPROFILECREATE,
      CHARGEPOINTCONNECTIONPROFILEDELETE,
      PARTNERREAD,
      PARTNERCREATE,
      TAXATIONREAD,
      TAXATIONWRITE,
      BOTENANNA,
      EXTERNALROAMINGINTEGRATIONSREAD,
      MANAGEPLATFORM,
      EVMODELSREAD,
      PRICEPROFILEBULKWRITE,
      PRICEASSOCIATIONBULKWRITE,
      AUTHRESULTREAD,
    ],
  });

export const FIRMWARES = () =>
  Link.static({
    nameLocaleKey: 'firmwares',
    pathPart: 'firmwares',
    parent: ADMIN(),
    allowedPrivileges: [CHARGEPOINTFIRMWARECREATE],
  });

export const PARTNERS = () =>
  Link.static({
    nameLocaleKey: 'partners',
    pathPart: 'partners',
    parent: ADMIN(),
    allowedPrivileges: [PARTNERREAD, PARTNERCREATE],
  });

export const CONNECTION_PROFILES = () =>
  Link.static({
    nameLocaleKey: 'connectionProfiles',
    pathPart: 'connection-profiles',
    parent: ADMIN(),
    allowedPrivileges: [CHARGEPOINTCONNECTIONPROFILECREATE, CHARGEPOINTCONNECTIONPROFILEDELETE],
  });

export const CREATE_CONNECTION_PROFILE = () =>
  Link.static({
    nameLocaleKey: 'connectionProfileAdd',
    pathPart: 'create-connection-profle',
    parent: CONNECTION_PROFILES(),
  });

export const CREATE_FIRMWARE = () =>
  Link.static({
    nameLocaleKey: 'firmwareAdd',
    parent: FIRMWARES(),
    pathPart: 'create',
  });

export const CREATE_PARTNER = () =>
  Link.static({
    nameLocaleKey: 'partnerAdd',
    pathPart: 'create',
    parent: PARTNERS(),
  });

export const ELECTRIC_VEHICLES = () =>
  Link.static({
    nameLocaleKey: 'electricVehicles',
    pathPart: 'electric-vehicles',
    parent: ADMIN(),
    allowedPrivileges: [EVMODELSREAD],
  });

export const CREATE_ELECTRIC_VEHICLE = () =>
  Link.static({
    nameLocaleKey: 'electricVehiclesAdd',
    pathPart: 'create',
    parent: ELECTRIC_VEHICLES(),
  });

export const EMP_ROAMING_CDRs = () =>
  Link.static({
    nameLocaleKey: 'empRoamingCDRs',
    pathPart: 'emp-roaming-cdrs',
    parent: ADMIN(),
    allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSREAD],
  });

export const EMP_ROAMING_PULL_JOBS = () =>
  Link.static({
    nameLocaleKey: 'empRoamingPullJobs',
    pathPart: 'emp-roaming-pull-jobs',
    parent: ADMIN(),
    allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSREAD],
  });

export const EMP_ROAMING_PULL_JOB_LINK = ({ id, name }: DynamicLinkProps): Link =>
  new Link({
    name,
    pathPart: id,
    parent: EMP_ROAMING_PULL_JOBS(),
    allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSREAD],
  });

export const EMP_ROAMING_PULL_JOBS_CREATE = () =>
  Link.static({
    nameLocaleKey: 'empRoamingPullJobsCreate',
    pathPart: 'create',
    parent: EMP_ROAMING_PULL_JOBS(),
    allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSCREATE, EXTERNALROAMINGINTEGRATIONSUPDATE],
  });

export const EMP_ROAMING_PULL_JOBS_EDIT = ({ id }: { id: string }) =>
  Link.static({
    nameLocaleKey: 'empRoamingPullJobsEdit',
    pathPart: `${id}/edit`,
    parent: EMP_ROAMING_PULL_JOBS(),
    allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSCREATE, EXTERNALROAMINGINTEGRATIONSUPDATE],
  });

export const USER_ROLES = () =>
  Link.static({
    nameLocaleKey: 'userRoles',
    pathPart: 'user-roles',
    parent: ADMIN(),
    allowedPrivileges: [MANAGEPLATFORM],
  });

export const adminFirmwareLink = (
  { id, name }: DynamicLinkProps = {
    id: ':id',
    name: '',
  },
): Link => new Link({ name, pathPart: id, parent: FIRMWARES(), allowedPrivileges: [CHARGEPOINTFIRMWARECREATE] });

export const connectionProfileLink = ({ id, name }: DynamicLinkProps = { id: ':id', name: '' }): Link =>
  new Link({
    name,
    pathPart: id,
    parent: CONNECTION_PROFILES(),
  });

export const loadBalancingGroupLink = (parent: Link, { id, name }: DynamicLinkProps): Link =>
  new Link({
    name,
    pathPart: id,
    parent,
  });

export const loadBalancingGroupsLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'loadBalancing',
    pathPart: 'load-balancing-groups',
    parent,
    allowedPrivileges: [SMARTCHARGINGREAD],
  });

export const partnerLink = ({ id, name }: DynamicLinkProps): Link =>
  new Link({
    name,
    pathPart: id,
    parent: PARTNERS(),
  });

export const CPO_DISPLAY_NAME_EDIT = (): Link =>
  Link.static({
    nameLocaleKey: 'cpoDisplayNameBulkEditHeader',
    pathPart: 'cpo-display-name',
    parent: ADMIN(),
    allowedPrivileges: [CHARGEPOINTFIRMWARECREATE],
  });

export const PRICE_PROFILE_BULK_UPLOAD = (): Link =>
  Link.static({
    nameLocaleKey: 'priceProfileBulkUpload',
    pathPart: 'price-profile-bulk-upload',
    parent: ADMIN(),
    allowedPrivileges: [PRICEPROFILEBULKWRITE],
  });

export const BOTEN_ANNA = (): Link =>
  Link.static({
    nameLocaleKey: 'botenAnna',
    pathPart: 'boten-anna',
    parent: ADMIN(),
    allowedPrivileges: [BOTENANNA],
  });

export const VAT_MANAGEMENT = () =>
  Link.static({
    nameLocaleKey: 'vat',
    pathPart: 'vat-master',
    parent: ADMIN(),
    allowedPrivileges: [TAXATIONREAD, TAXATIONWRITE],
  });

export const MANUAL_TARIFF_MODULE = () =>
  Link.static({
    nameLocaleKey: 'manualTariffModule',
    pathPart: 'manual-tariff-module',
    parent: ADMIN(),
    allowedPrivileges: [PRICEPROFILEBULKWRITE, PRICEASSOCIATIONBULKWRITE],
  });

export const CHARGING_AUTH_RESULTS = () =>
  Link.static({
    nameLocaleKey: 'chargingAuthResults',
    pathPart: 'charging-auth-results',
    parent: ADMIN(),
    allowedPrivileges: [AUTHRESULTREAD],
  });
