import { Auth } from '@aws-amplify/auth';

export function configure(userPoolId: string, clientId: string) {
  const config = {
    Auth: {
      userPoolId,
      clientId,
      userPoolWebClientId: clientId,
    },
  };
  Auth.configure(config);
}
