// md5:623eb91a651639766cb4bf1836e3fa33
import { emptySplitApi as api } from 'services/emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getCountryIsoCodesUsingGet: build.query<GetCountryIsoCodesUsingGetApiResponse, GetCountryIsoCodesUsingGetApiArg>({
      query: () => ({ url: `/v1/master-data/countries` }),
    }),
    findCountryCodesByCurrencyCodeUsingGet: build.query<
      FindCountryCodesByCurrencyCodeUsingGetApiResponse,
      FindCountryCodesByCurrencyCodeUsingGetApiArg
    >({
      query: queryArg => ({
        url: `/v1/master-data/countries/currency/${queryArg.currencyCode}`,
        params: { atTime: queryArg.atTime },
      }),
    }),
    getEvManufacturersUsingGet: build.query<GetEvManufacturersUsingGetApiResponse, GetEvManufacturersUsingGetApiArg>({
      query: () => ({ url: `/v1/master-data/electric-vehicles/manufacturers` }),
    }),
    createEvManufacturerUsingPost: build.mutation<
      CreateEvManufacturerUsingPostApiResponse,
      CreateEvManufacturerUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/master-data/electric-vehicles/manufacturers`,
        method: 'POST',
        body: queryArg.createElectricVehicleManufacturerRequest,
      }),
    }),
    addEvModelToEvManufacturerUsingPost: build.mutation<
      AddEvModelToEvManufacturerUsingPostApiResponse,
      AddEvModelToEvManufacturerUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/master-data/electric-vehicles/manufacturers/${queryArg.manufacturerId}/models`,
        method: 'POST',
        body: queryArg.createElectricVehicleModelRequest,
      }),
    }),
    getLanguagesUsingGet: build.query<GetLanguagesUsingGetApiResponse, GetLanguagesUsingGetApiArg>({
      query: () => ({ url: `/v1/master-data/languages` }),
    }),
    listCountryTaxationSettingsUsingGet: build.query<
      ListCountryTaxationSettingsUsingGetApiResponse,
      ListCountryTaxationSettingsUsingGetApiArg
    >({
      query: () => ({ url: `/v1/master-data/taxation` }),
    }),
    scheduleNewTaxationUsingPut: build.mutation<
      ScheduleNewTaxationUsingPutApiResponse,
      ScheduleNewTaxationUsingPutApiArg
    >({
      query: queryArg => ({
        url: `/v1/master-data/taxation/${queryArg.countryCode}/scheduled`,
        method: 'PUT',
        body: queryArg.cdmTaxation,
      }),
    }),
    cancelScheduledNewTaxationUsingDelete: build.mutation<
      CancelScheduledNewTaxationUsingDeleteApiResponse,
      CancelScheduledNewTaxationUsingDeleteApiArg
    >({
      query: queryArg => ({ url: `/v1/master-data/taxation/${queryArg.countryCode}/scheduled`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v1MasterDataApi };
export type GetCountryIsoCodesUsingGetApiResponse = /** status 200 OK */ Country[];
export type GetCountryIsoCodesUsingGetApiArg = void;
export type FindCountryCodesByCurrencyCodeUsingGetApiResponse = /** status 200 OK */ string[];
export type FindCountryCodesByCurrencyCodeUsingGetApiArg = {
  /** currencyCode */
  currencyCode: string;
  /** atTime */
  atTime?: number;
};
export type GetEvManufacturersUsingGetApiResponse = /** status 200 OK */ ElectricVehicleManufacturer[];
export type GetEvManufacturersUsingGetApiArg = void;
export type CreateEvManufacturerUsingPostApiResponse = /** status 200 OK */ ElectricVehicleManufacturer;
export type CreateEvManufacturerUsingPostApiArg = {
  /** request */
  createElectricVehicleManufacturerRequest: CreateElectricVehicleManufacturerRequest;
};
export type AddEvModelToEvManufacturerUsingPostApiResponse = /** status 200 OK */ ElectricVehicleManufacturer;
export type AddEvModelToEvManufacturerUsingPostApiArg = {
  /** manufacturerId */
  manufacturerId: string;
  /** request */
  createElectricVehicleModelRequest: CreateElectricVehicleModelRequest;
};
export type GetLanguagesUsingGetApiResponse = /** status 200 OK */ Language[];
export type GetLanguagesUsingGetApiArg = void;
export type ListCountryTaxationSettingsUsingGetApiResponse = /** status 200 OK */ CdmCountryTaxationSetting[];
export type ListCountryTaxationSettingsUsingGetApiArg = void;
export type ScheduleNewTaxationUsingPutApiResponse = /** status 200 OK */ void;
export type ScheduleNewTaxationUsingPutApiArg = {
  /** countryCode */
  countryCode: string;
  /** taxation */
  cdmTaxation: CdmTaxation;
};
export type CancelScheduledNewTaxationUsingDeleteApiResponse = /** status 200 OK */ void;
export type CancelScheduledNewTaxationUsingDeleteApiArg = {
  /** countryCode */
  countryCode: string;
};
export type Currency = {
  code: string;
  name: string;
};
export type Taxation = {
  rate: number;
  rateCharging: number;
  rateProducts: number;
  rateSubscriptions: number;
  resellerReverseCharge: boolean;
  supportsReverseCharge: boolean;
  validFrom: string;
  validTo: string;
};
export type Timezone = {
  zoneId: string;
};
export type Country = {
  alpha2Code: string;
  alpha3Code: string;
  currency: Currency;
  iso31662Code: string;
  name: string;
  numericCode: string;
  supportsVatValidation: boolean;
  taxation: Taxation;
  timezones: Timezone[];
};
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export type ElectricVehicleModel = {
  id: string;
  name: string;
  type: 'CAR' | 'MOTORCYCLE' | 'THREE_WHEELER';
};
export type ElectricVehicleManufacturer = {
  id: string;
  models: ElectricVehicleModel[];
  name: string;
};
export type CreateElectricVehicleManufacturerRequest = {
  name: string;
};
export type CreateElectricVehicleModelRequest = {
  name: string;
  type: 'CAR' | 'MOTORCYCLE' | 'THREE_WHEELER';
};
export type Language = {
  code: string;
  icon: string;
  name: string;
};
export type CdmTaxation = {
  rateCharging: number;
  rateProducts: number;
  rateSubscriptions: number;
  validFrom: string;
  validTo?: string;
};
export type CdmCountryTaxationSetting = {
  alpha2Code: string;
  scheduledTaxation?: CdmTaxation;
  taxation: CdmTaxation;
};
export const {
  useGetCountryIsoCodesUsingGetQuery,
  useFindCountryCodesByCurrencyCodeUsingGetQuery,
  useGetEvManufacturersUsingGetQuery,
  useCreateEvManufacturerUsingPostMutation,
  useAddEvModelToEvManufacturerUsingPostMutation,
  useGetLanguagesUsingGetQuery,
  useListCountryTaxationSettingsUsingGetQuery,
  useScheduleNewTaxationUsingPutMutation,
  useCancelScheduledNewTaxationUsingDeleteMutation,
} = injectedRtkApi;
