import { IconName } from '@plugsurfing/plugsurfing-design';
import type { Privilege } from 'cdm-api-client/v1RolesApi';
import CdPageSection, { CdPageSectionProps } from 'components/design-elements/CdPageSection';
import { t } from 'i18n';
import noop from 'lodash/noop';
import { PureComponent, createContext, memo, useMemo, useState, type ContextType, type ReactNode } from 'react';
import { createComponentWithPrivilegeAuthorization, type PrivilegeCheck } from 'utils/roles';
import { CdCard, CdLinkButton } from '../';
import styles from './CdEditableCardWithoutControls.module.scss';

interface CdEditableCardGroupProps {
  children?: ReactNode;
}

export interface CdEditableCardContextType {
  activeCardKey: string | undefined;
  setActive: (cardKey?: string) => void;
  setInactive: () => void;
}

export const CdEditableCardContext = createContext<CdEditableCardContextType>({
  activeCardKey: undefined,
  setActive: noop,
  setInactive: noop,
});

export const CdEditableCardGroup = memo(({ children }: CdEditableCardGroupProps) => {
  const [activeCardKey, setActiveKey] = useState<string>();
  const context = useMemo<CdEditableCardContextType>(
    () => ({
      activeCardKey,
      setActive: (key?: string) => setActiveKey(key),
      setInactive: () => setActiveKey(undefined),
    }),
    [activeCardKey],
  );

  return <CdEditableCardContext.Provider value={context}>{children}</CdEditableCardContext.Provider>;
});

export interface CdEditableCardWithoutControlsProps extends CdPageSectionProps, PrivilegeCheck {
  variantOnView?: 'card' | 'section';
  variantOnEdit?: 'card' | 'section';
  topRight?: JSX.Element;
  topRightLabel?: string | false;
  topRightIconName?: IconName;
  topRightCancelLabel?: string;
  topRightCancelIconName?: IconName;
  editComponent: JSX.Element | undefined;
  displayComponent: JSX.Element | undefined;
  cardKey: string;
  allowedEditPrivileges?: Privilege | Privilege[];
  allowedDeletePrivileges?: Privilege | Privilege[];
  disabled?: boolean;
  loading?: boolean;
  onRemove?(): void;
  onActivate?(): void;
  isRemoveBtnDisabled?: boolean;
}

export default class CdEditableCardWithoutControls extends PureComponent<
  CdEditableCardWithoutControlsProps,
  object,
  CdEditableCardContextType
> {
  static contextType = CdEditableCardContext;
  declare context: ContextType<typeof CdEditableCardContext>;

  Link = this.props.allowedPrivileges
    ? createComponentWithPrivilegeAuthorization(
        this.props.allowedPrivileges,
        CdLinkButton,
        undefined,
        this.props.allowedModules,
      )
    : CdLinkButton;

  handleTopRightClick = () => {
    if (this.context.setActive === noop) {
      this.context.activeCardKey = this.isActive ? undefined : this.props.cardKey;
    }
    this.context.setActive(this.isActive ? undefined : this.props.cardKey);
    if (this.props.onActivate !== undefined) {
      this.props.onActivate();
    }
  };

  get isAvailable(): boolean {
    return !!this.context.activeCardKey;
  }

  get isActive(): boolean {
    return this.context.activeCardKey === this.props.cardKey;
  }

  render() {
    const {
      variantOnView = 'card',
      variantOnEdit = 'card',
      editComponent,
      displayComponent,
      onRemove,
      allowedPrivileges,
      allowedDeletePrivileges,
      allowedEditPrivileges,
      cardKey,
      topRight,
      topRightCancelIconName,
      topRightIconName,
      topRightCancelLabel,
      topRightLabel = t('edit'),
      disabled,
      isRemoveBtnDisabled = false,
      ...props
    } = this.props;
    const isEditing = this.context.activeCardKey === cardKey;
    const componentProps: CdPageSectionProps = {
      ...props,
      children: !props.loading && (isEditing ? editComponent : displayComponent),
      topRight:
        topRight === undefined
          ? topRightLabel && (
              <div className={styles.topButtonContainer}>
                {onRemove && (
                  <CdLinkButton
                    leftIcon="Bin"
                    onClick={onRemove}
                    label={t('remove')}
                    allowedPrivileges={allowedDeletePrivileges || allowedPrivileges}
                    isDisabled={isRemoveBtnDisabled}
                  />
                )}
                <this.Link
                  whiteSpace="break-spaces"
                  leftIcon={this.isActive ? topRightCancelIconName || 'Close' : topRightIconName || 'Edit'}
                  isDisabled={disabled || (this.isAvailable && !this.isActive)}
                  onClick={this.handleTopRightClick}
                  label={this.isActive ? topRightCancelLabel || t('cancel') : topRightLabel || t('edit')}
                  allowedPrivileges={allowedEditPrivileges}
                />
              </div>
            )
          : topRight,
    };

    return (isEditing && variantOnEdit === 'card') || (!isEditing && variantOnView === 'card') ? (
      <CdCard {...componentProps} />
    ) : (
      <CdPageSection {...componentProps} />
    );
  }
}
