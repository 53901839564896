// md5:5c7adb85c64d0c59124bae94b04d05d1
import { emptySplitApi as api } from 'services/emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getOwnerPaymentAccountsUsingGet: build.query<
      GetOwnerPaymentAccountsUsingGetApiResponse,
      GetOwnerPaymentAccountsUsingGetApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/customers`,
        params: { keyId: queryArg.keyId, ownerId: queryArg.ownerId, ownerType: queryArg.ownerType },
      }),
    }),
    createCustomerAccountUsingPost: build.mutation<
      CreateCustomerAccountUsingPostApiResponse,
      CreateCustomerAccountUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/customers`,
        method: 'POST',
        body: queryArg.apiCreateAccountRequest,
      }),
    }),
    getParentOrganizationAcceptedPaymentMethodsUsingGet: build.query<
      GetParentOrganizationAcceptedPaymentMethodsUsingGetApiResponse,
      GetParentOrganizationAcceptedPaymentMethodsUsingGetApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/customers/payment-methods`,
        params: { organizationId: queryArg.organizationId },
      }),
    }),
    createPrepaidBalanceUsingPost: build.mutation<
      CreatePrepaidBalanceUsingPostApiResponse,
      CreatePrepaidBalanceUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/customers/prepaid/credit`,
        method: 'POST',
        body: queryArg.createPrepaidCreditRequest,
      }),
    }),
    createCustomerVbanUsingPost: build.mutation<
      CreateCustomerVbanUsingPostApiResponse,
      CreateCustomerVbanUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/customers/vban`,
        method: 'POST',
        body: queryArg.createVbanRequest,
      }),
    }),
    createExternalCustomerAccountUsingPost: build.mutation<
      CreateExternalCustomerAccountUsingPostApiResponse,
      CreateExternalCustomerAccountUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/customers/${queryArg.ownerId}/external/${queryArg.ownerType}`,
        method: 'POST',
      }),
    }),
    getUserAccountUsingGet: build.query<GetUserAccountUsingGetApiResponse, GetUserAccountUsingGetApiArg>({
      query: queryArg => ({ url: `/v1/payment-accounts/customers/${queryArg.paymentOptionReference}` }),
    }),
    getCustomerVbanBalanceUsingGet: build.query<
      GetCustomerVbanBalanceUsingGetApiResponse,
      GetCustomerVbanBalanceUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v1/payment-accounts/customers/${queryArg.paymentOptionReference}/vban/balance` }),
    }),
    getBillingPlansForOrganizationUsingGet: build.query<
      GetBillingPlansForOrganizationUsingGetApiResponse,
      GetBillingPlansForOrganizationUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v1/payment-accounts/organizations/${queryArg.organizationId}/billing/plans` }),
    }),
    addCurrencyToOrganizationMainPlanUsingPost: build.mutation<
      AddCurrencyToOrganizationMainPlanUsingPostApiResponse,
      AddCurrencyToOrganizationMainPlanUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/organizations/${queryArg.organizationId}/billing/plans/currency/${queryArg.currency}`,
        method: 'POST',
      }),
    }),
    getOrganizationAcceptedPaymentMethodsUsingGet: build.query<
      GetOrganizationAcceptedPaymentMethodsUsingGetApiResponse,
      GetOrganizationAcceptedPaymentMethodsUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v1/payment-accounts/organizations/${queryArg.organizationId}/payment-methods` }),
    }),
    createOrganizationAcceptedPaymentMethodUsingPost: build.mutation<
      CreateOrganizationAcceptedPaymentMethodUsingPostApiResponse,
      CreateOrganizationAcceptedPaymentMethodUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/organizations/${queryArg.organizationId}/payment-methods`,
        method: 'POST',
        body: queryArg.apiCreatePaymentMethodForOrganizationRequest,
      }),
    }),
    toggleOrganizationAcceptedPaymentMethodUsingPost: build.mutation<
      ToggleOrganizationAcceptedPaymentMethodUsingPostApiResponse,
      ToggleOrganizationAcceptedPaymentMethodUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/organizations/${queryArg.organizationId}/payment-methods/toggle`,
        method: 'POST',
        body: queryArg.apiToggleOrganizationAcceptedPaymentMethodsRequest,
      }),
    }),
    getOrganizationConnectAccountUsingGet: build.query<
      GetOrganizationConnectAccountUsingGetApiResponse,
      GetOrganizationConnectAccountUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v1/payment-accounts/organizations/${queryArg.organizationId}/stripe-connect` }),
    }),
    createStripeConnectAccountFromUrlUsingPost: build.mutation<
      CreateStripeConnectAccountFromUrlUsingPostApiResponse,
      CreateStripeConnectAccountFromUrlUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/organizations/${queryArg.organizationId}/stripe-connect/create`,
        method: 'POST',
        body: queryArg.createStripeConnectAccountRequest,
      }),
    }),
    refundASessionUsingPost: build.mutation<RefundASessionUsingPostApiResponse, RefundASessionUsingPostApiArg>({
      query: queryArg => ({
        url: `/v1/payment-accounts/organizations/${queryArg.organizationId}/stripe-connect/request-refund`,
        method: 'POST',
        body: queryArg.refundSessionApiRequest,
      }),
    }),
    updateStripeConnectAccountKycUsingPost: build.mutation<
      UpdateStripeConnectAccountKycUsingPostApiResponse,
      UpdateStripeConnectAccountKycUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/payment-accounts/organizations/${queryArg.organizationId}/stripe-connect/update/kyc`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v1PaymentAccountsApi };
export type GetOwnerPaymentAccountsUsingGetApiResponse = /** status 200 OK */ ApiPaymentAccount[];
export type GetOwnerPaymentAccountsUsingGetApiArg = {
  /** keyId */
  keyId?: string;
  /** ownerId */
  ownerId?: string;
  /** ownerType */
  ownerType?: 'ORGANIZATION' | 'USER';
};
export type CreateCustomerAccountUsingPostApiResponse = /** status 200 OK */ ApiPaymentAccount;
export type CreateCustomerAccountUsingPostApiArg = {
  /** request */
  apiCreateAccountRequest: ApiCreateAccountRequest;
};
export type GetParentOrganizationAcceptedPaymentMethodsUsingGetApiResponse = /** status 200 OK */ (
  | 'STRIPE_CREDIT_CARD'
  | 'STRIPE_SEPA'
  | 'STRIPE_BILLING_CREDIT_CARD'
  | 'STRIPE_BILLING_SEPA'
  | 'STRIPE_BILLING_SEPA_CREDIT_TRANSFER'
  | 'ELWIN'
  | 'PREPAID'
  | 'STRIPE'
  | 'EXTERNAL'
  | 'UNKNOWN'
)[];
export type GetParentOrganizationAcceptedPaymentMethodsUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
};
export type CreatePrepaidBalanceUsingPostApiResponse = /** status 200 OK */ CreatePrepaidCreditResponse;
export type CreatePrepaidBalanceUsingPostApiArg = {
  /** request */
  createPrepaidCreditRequest: CreatePrepaidCreditRequest;
};
export type CreateCustomerVbanUsingPostApiResponse = /** status 200 OK */ CreateVbanResponse;
export type CreateCustomerVbanUsingPostApiArg = {
  /** request */
  createVbanRequest: CreateVbanRequest;
};
export type CreateExternalCustomerAccountUsingPostApiResponse = /** status 200 OK */ ApiPaymentAccount;
export type CreateExternalCustomerAccountUsingPostApiArg = {
  /** ownerId */
  ownerId: string;
  /** ownerType */
  ownerType: 'ORGANIZATION' | 'USER';
};
export type GetUserAccountUsingGetApiResponse = /** status 200 OK */ ApiPaymentAccount;
export type GetUserAccountUsingGetApiArg = {
  /** paymentOptionReference */
  paymentOptionReference: string;
};
export type GetCustomerVbanBalanceUsingGetApiResponse = /** status 200 OK */ VbanBalanceResponse;
export type GetCustomerVbanBalanceUsingGetApiArg = {
  /** paymentOptionReference */
  paymentOptionReference: string;
};
export type GetBillingPlansForOrganizationUsingGetApiResponse = /** status 200 OK */ BillingPlan[];
export type GetBillingPlansForOrganizationUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
};
export type AddCurrencyToOrganizationMainPlanUsingPostApiResponse = /** status 200 OK */ BillingPlan;
export type AddCurrencyToOrganizationMainPlanUsingPostApiArg = {
  /** organizationId */
  organizationId: string;
  /** currency */
  currency: string;
};
export type GetOrganizationAcceptedPaymentMethodsUsingGetApiResponse = /** status 200 OK */ (
  | 'STRIPE_CREDIT_CARD'
  | 'STRIPE_SEPA'
  | 'STRIPE_BILLING_CREDIT_CARD'
  | 'STRIPE_BILLING_SEPA'
  | 'STRIPE_BILLING_SEPA_CREDIT_TRANSFER'
  | 'ELWIN'
  | 'PREPAID'
  | 'STRIPE'
  | 'EXTERNAL'
  | 'UNKNOWN'
)[];
export type GetOrganizationAcceptedPaymentMethodsUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
};
export type CreateOrganizationAcceptedPaymentMethodUsingPostApiResponse = /** status 200 OK */ (
  | 'STRIPE_CREDIT_CARD'
  | 'STRIPE_SEPA'
  | 'STRIPE_BILLING_CREDIT_CARD'
  | 'STRIPE_BILLING_SEPA'
  | 'STRIPE_BILLING_SEPA_CREDIT_TRANSFER'
  | 'ELWIN'
  | 'PREPAID'
  | 'STRIPE'
  | 'EXTERNAL'
  | 'UNKNOWN'
)[];
export type CreateOrganizationAcceptedPaymentMethodUsingPostApiArg = {
  /** organizationId */
  organizationId: string;
  /** request */
  apiCreatePaymentMethodForOrganizationRequest: ApiCreatePaymentMethodForOrganizationRequest;
};
export type ToggleOrganizationAcceptedPaymentMethodUsingPostApiResponse =
  /** status 200 OK */ OrganizationAcceptedPaymentProvider;
export type ToggleOrganizationAcceptedPaymentMethodUsingPostApiArg = {
  /** organizationId */
  organizationId: string;
  /** request */
  apiToggleOrganizationAcceptedPaymentMethodsRequest: ApiToggleOrganizationAcceptedPaymentMethodsRequest;
};
export type GetOrganizationConnectAccountUsingGetApiResponse = /** status 200 OK */ StripeConnectAccount;
export type GetOrganizationConnectAccountUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
};
export type CreateStripeConnectAccountFromUrlUsingPostApiResponse = /** status 200 OK */ StripeAccountLink;
export type CreateStripeConnectAccountFromUrlUsingPostApiArg = {
  /** organizationId */
  organizationId: string;
  /** request */
  createStripeConnectAccountRequest: CreateStripeConnectAccountRequest;
};
export type RefundASessionUsingPostApiResponse = /** status 200 OK */ ApiRefund;
export type RefundASessionUsingPostApiArg = {
  /** organizationId */
  organizationId: string;
  /** request */
  refundSessionApiRequest: RefundSessionApiRequest;
};
export type UpdateStripeConnectAccountKycUsingPostApiResponse = /** status 200 OK */ StripeAccountLink;
export type UpdateStripeConnectAccountKycUsingPostApiArg = {
  /** organizationId */
  organizationId: string;
};
export type PrePaidData = {
  prePaidAmount: string;
  currency: string;
  amount?: string;
};
export type StripeData = {
  stripeId: string;
  card: {
    brand: string;
    displayBrand?: string;
    country: string;
    expMonth: string;
    expYear: string;
    last4: string;
  };
};
export type ElwinData = {
  elwinId: string;
};
export type BillingCreditTransferData = {
  paymentMethodType: 'SEPA_CREDIT_TRANSFER';
  currency: string;
  bankName: string;
  iban: string;
  subscriptionId: string;
  bic: string;
};
export type BillingCardData = {
  paymentMethodType: 'CARD';
  currency: string;
  expiryMonth: number;
  expiryYear: number;
  lastFour: string;
  displayBrand: string;
  organizationId: {
    value: number;
  };
  subscriptionId: string;
  type: string;
};
export type BillingSepaData = {
  paymentMethodType: 'SEPA';
  currency: string;
  ibanLastFour: string;
  subscriptionId: string;
};
export type ApiPaymentAccount = {
  default?: boolean;
  nickname?: string;
  paymentOptionId: string;
} & (
  | {
      paymentMethod: 'PREPAID';
      data: PrePaidData;
    }
  | {
      paymentMethod: 'STRIPE';
      data: StripeData;
    }
  | {
      paymentMethod: 'ELWIN';
      data: ElwinData;
    }
  | {
      paymentMethod: 'BILLING';
      data: BillingCreditTransferData | BillingCardData | BillingSepaData;
    }
  | {
      paymentMethod: 'EXTERNAL';
    }
);
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export type Account = {
  accountOwnerId: string;
  accountType: 'USER' | 'ORGANISATION';
  data: object;
  paymentMethod: 'PREPAID' | 'STRIPE' | 'ELWIN' | 'BILLING' | 'EXTERNAL';
};
export type ApiCreateAccountRequest = {
  account: Account;
};
export type CreatePrepaidCreditResponse = {
  amountMinorUnits: number;
  currency: string;
  expiryDate: string;
  id: string;
};
export type CreatePrepaidCreditRequest = {
  amountMinorUnits: number;
  currency: string;
  empOrganizationId: string;
  expiryDate: string;
  paymentOptionId: string;
};
export type CreateVbanResponse = {
  bankName: string;
  bic: string;
  iban: string;
  id: string;
};
export type CreateVbanRequest = {
  ownerEmail: string;
  ownerName: string;
  ownerOrganizationId: string;
};
export type VbanBalanceResponse = {
  amountCharged: number;
  amountReceived: number;
  balance: number;
  currency: string;
};
export type DayOfInvoice = {
  dayOfMonth: number;
};
export type Interval = {
  count: number;
  type: 'DAY' | 'MONTH' | 'WEEK' | 'YEAR';
};
export type BillingPlan = {
  currencies: string[];
  dayOfInvoice: DayOfInvoice;
  daysUntilDue: number;
  interval: Interval;
  name: string;
};
export type ApiCreatePaymentMethodForOrganizationRequest = {
  paymentMethod: 'CREDIT_CARD' | 'SEPA_DEBIT' | 'SEPA_CT';
  paymentProvider:
    | 'CHARGE_AND_DRIVE'
    | 'STRIPE'
    | 'ELWIN'
    | 'LINK_MOBILITY'
    | 'BILLING'
    | 'EXTERNAL'
    | 'PAYTER'
    | 'UNKNOWN';
};
export type OrganizationId = {
  value: number;
};
export type OrganizationAcceptedPaymentProvider = {
  active: boolean;
  organizationId: OrganizationId;
  paymentMethods: ('CREDIT_CARD' | 'SEPA_DEBIT' | 'SEPA_CT')[];
  paymentProvider:
    | 'CHARGE_AND_DRIVE'
    | 'STRIPE'
    | 'ELWIN'
    | 'LINK_MOBILITY'
    | 'BILLING'
    | 'EXTERNAL'
    | 'PAYTER'
    | 'UNKNOWN';
};
export type ApiToggleOrganizationAcceptedPaymentMethodsRequest = {
  active: boolean;
  paymentProvider:
    | 'CHARGE_AND_DRIVE'
    | 'STRIPE'
    | 'ELWIN'
    | 'LINK_MOBILITY'
    | 'BILLING'
    | 'EXTERNAL'
    | 'PAYTER'
    | 'UNKNOWN';
};
export type ConnectBankAccount = {
  bankName: string;
  country: string;
  currency: string;
  lastFour: string;
};
export type StripeConnectAccount = {
  accountType: 'CONNECT' | 'STANDALONE';
  bankAccount: ConnectBankAccount;
  billingApplicationFeePercent: number;
  connectAccountId: string;
  currency: string;
  organizationId: string;
  statementDescriptor: string;
  status: 'ACTIVE' | 'INACTIVE' | 'PENDING';
};
export type StripeAccountLink = {
  accountType: 'CONNECT' | 'STANDALONE';
  connectAccountId: string;
  organizationId: string;
  url: string;
};
export type CreateStripeConnectAccountRequest = {
  externalAccountToken: string;
  statementDescriptor: string;
};
export type ApiRefund = {
  currency: string;
  minorUnitsInclVat: number;
  status: string;
};
export type RefundSessionApiRequest = {
  amountMinorUnitsInclVat: number;
  paymentProviderTransactionId: string;
  sessionId: string;
};
export const {
  useGetOwnerPaymentAccountsUsingGetQuery,
  useCreateCustomerAccountUsingPostMutation,
  useGetParentOrganizationAcceptedPaymentMethodsUsingGetQuery,
  useCreatePrepaidBalanceUsingPostMutation,
  useCreateCustomerVbanUsingPostMutation,
  useCreateExternalCustomerAccountUsingPostMutation,
  useGetUserAccountUsingGetQuery,
  useGetCustomerVbanBalanceUsingGetQuery,
  useGetBillingPlansForOrganizationUsingGetQuery,
  useAddCurrencyToOrganizationMainPlanUsingPostMutation,
  useGetOrganizationAcceptedPaymentMethodsUsingGetQuery,
  useCreateOrganizationAcceptedPaymentMethodUsingPostMutation,
  useToggleOrganizationAcceptedPaymentMethodUsingPostMutation,
  useGetOrganizationConnectAccountUsingGetQuery,
  useCreateStripeConnectAccountFromUrlUsingPostMutation,
  useRefundASessionUsingPostMutation,
  useUpdateStripeConnectAccountKycUsingPostMutation,
} = injectedRtkApi;
