import { Button, Flex } from '@plugsurfing/plugsurfing-design';
import * as Links from 'config/links';
import { useTranslation } from 'react-i18next';
import { createQueryString, history } from 'utils/helpers';
import { SearchResult } from 'views/powerSearch/utils/matchActions';
import { usePowerSearch } from 'views/powerSearch/utils/PowerSearchContext';
import { EntityScope } from 'views/powerSearch/utils/useCombinedSearch';

type SearchResultCountProps = {
  result: SearchResult;
};

export const SearchResultCount = ({ result }: SearchResultCountProps) => {
  const { t } = useTranslation();
  const { searchText, toggleOpen, setHoveredEntity } = usePowerSearch();
  const currentEntityLink = mapEntityToLink(result, searchText);

  const onLinkPress = () => {
    history.replace(currentEntityLink);
    toggleOpen.off();
    setHoveredEntity(undefined);
  };

  const blacklistedTypes = ['link', 'external-chargepoint'];

  return (
    <Flex justifyContent="flex-end" mt={result.count <= 3 ? 'component.m' : undefined}>
      {result.count > 3 && !blacklistedTypes.includes(result.type) && (
        <Button size="XS" variant="tertiary" onClick={() => onLinkPress()}>
          {t('and')} {result.count - 3} more
        </Button>
      )}
    </Flex>
  );
};

const mapEntityToLink = (entity: SearchResult, query: string) => {
  const scope = entity.scope;
  const queryParams = createQueryString({ [entity.searchedBy]: query, organization: [createOrganizationQuery(scope)] });

  switch (entity.type) {
    case 'charging-key':
      return (
        Links.CHARGING_KEYS().path +
        createQueryString({ [entity.searchedBy]: query, organization: [createOrganizationQuery(scope)] })
      );
    case 'customer':
      return Links.CUSTOMERS().path + queryParams;
    case 'external-chargepoint':
      return (
        Links.EXTERNAL_CHARGING_STATIONS().path +
        createQueryString({
          [entity.searchedBy]: query,
          //   cpoName: encodeURIComponent(scope?.data.name || ''),
          tab: 'external',
        })
      );
    case 'internal-chargepoint':
      return (
        Links.CHARGING_STATIONS().path +
        createQueryString({
          [`internal.${entity.searchedBy}`]: query,
          'internal.cpos': scope ? [createOrganizationQuery(scope)] : [],
          tab: 'internal',
        })
      );
    case 'organization':
      return Links.ORGANIZATIONS().path + queryParams;
    default:
      return '';
  }
};

const createOrganizationQuery = (scope?: EntityScope) =>
  scope ? { name: encodeURIComponent(scope?.data.name), id: scope?.data.id } : '';
