import { CognitoUser } from '@aws-amplify/auth';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserAttributes } from 'models/cognito';
import { RootState } from 'redux/reducers';

export const enum AuthStatus {
  SignedOut = 'SIGNED_OUT',
  SignedIn = 'SIGNED_IN',
  Challenged = 'CHALLENGED',
  CompletingChallenge = 'COMPLETING_CHALLENGE',
  RestoringSession = 'RESTORING_SESSION',
  SigningIn = 'SIGNING_IN',
  SigningOut = 'SIGNING_OUT',
  RequestingPasswordResetToken = 'REQUESTED_PASSWORD_RESET_TOKEN',
  RequestingPasswordReset = 'REQUESTED_PASSWORD_RESET',
}
export type AuthState = Readonly<
  // "Stable" states
  | { status: AuthStatus.SignedOut; signInError?: unknown }
  | { status: AuthStatus.SignedIn }
  | { status: AuthStatus.Challenged; challenge: Challenge; completionError?: unknown }
  | { status: AuthStatus.RequestingPasswordResetToken }
  | { status: AuthStatus.RequestingPasswordReset; cognitoUsername: string }

  // "Transient" states
  | { status: AuthStatus.CompletingChallenge }
  | { status: AuthStatus.RestoringSession }
  | { status: AuthStatus.SigningIn }
  | { status: AuthStatus.SigningOut }
>;

export const enum ChallengeType {
  MFA = 'MFA',
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  CustomChallenge = 'CUSTOM_CHALLENGE',
}

export type Challenge = Readonly<
  | { type: ChallengeType.MFA; challengeParameters?: Record<string, string | undefined> }
  | {
      type: ChallengeType.NewPasswordRequired;
      userAttributes: UserAttributes;
      requiredAttributes: UserAttributes[];
    }
  | { type: ChallengeType.CustomChallenge; challengeParameters: Record<string, string> }
>;

export interface State {
  state: AuthState;
  temporaryUser?: CognitoUser;
}

export const initialState: State = {
  state: {
    status: AuthStatus.SignedOut,
  },
  temporaryUser: undefined as CognitoUser | undefined,
};

export default createSlice({
  name: 'authSlice',
  initialState: initialState,
  reducers: {
    updateState: (state, action: PayloadAction<AuthState>) => {
      state.state = action.payload;
    },
    updateTemporaryUser: (state, action: PayloadAction<CognitoUser>) => {
      state.temporaryUser = action.payload;
    },
  },
});

export const selectAuthState = createSelector(
  (state: RootState) => state.auth,
  state => state,
);

export const selectAuthStatus = createSelector(
  (state: RootState) => state.auth.state.status,
  status => status,
);

export const selectAuthChallenge = createSelector(
  (state: RootState) => state.auth.state,
  auth => (auth.status === AuthStatus.Challenged ? auth.challenge : undefined),
);

export const selectAuthUser = createSelector(
  (state: RootState) => state.auth.temporaryUser,
  user => user,
);

export const selectAuthError = createSelector(
  (state: RootState) => state.auth.state,
  auth => {
    switch (auth.status) {
      case AuthStatus.SignedOut:
        return auth.signInError;
      case AuthStatus.Challenged:
        return auth.completionError;
    }
  },
);
