import { Organization } from '@plugsurfing/cdm-api-client';
import { Box, Heading, Img } from '@plugsurfing/plugsurfing-design';
import { useGetOrganizationImagesUsingGetQuery } from 'cdm-api-client/v2OrganizationsApi';
import { CdKeyValue, CdLink } from 'components/design-elements';
import { organizationLink } from 'config/links';
import { getImage } from 'utils/views';

export const OrganizationPreview = ({ organization }: { organization: Organization }) => {
  const { data: organizationImagesConfig } = useGetOrganizationImagesUsingGetQuery({ organizationId: organization.id });
  const images = organizationImagesConfig?.config ? Object.entries(organizationImagesConfig?.config) : [];
  const orgLogo = images?.[1]?.[1];

  return (
    <Box>
      <Heading size="xs" mb="component.s">
        Organization
      </Heading>

      <CdKeyValue label="Name">{organization?.name}</CdKeyValue>
      <CdKeyValue label="Display Name">{organization?.displayName.displayName}</CdKeyValue>
      <CdKeyValue label="Id">{organization?.id}</CdKeyValue>
      <CdKeyValue label="Parent organization">
        <CdLink link={organizationLink(organization.parentOrganization)} />
      </CdKeyValue>
      {orgLogo && (
        <Box pt="component.s">
          <Img
            src={getImage(orgLogo?.LANDSCAPE)}
            h="100px"
            objectFit="scale-down"
            alignSelf="center"
            flexBasis={0}
            flexGrow={2}
          />
        </Box>
      )}
    </Box>
  );
};
