import { Checkbox, CheckboxProps } from '@plugsurfing/plugsurfing-design';
import { memo, ReactNode } from 'react';

export interface CdCheckboxProps extends Omit<CheckboxProps, 'children'> {
  /**
   * @deprecated use `isDisabled` instead
   */
  disabled?: boolean;
  /**
   * @deprecated use `isChecked` instead
   */
  checked?: boolean;
  label?: ReactNode;
}

const CdCheckbox = memo(({ disabled, checked, label, ...props }: CdCheckboxProps) => {
  return (
    <Checkbox isDisabled={disabled} isChecked={checked} my="component.3xs" {...props}>
      {label}
    </Checkbox>
  );
});

export default CdCheckbox;
