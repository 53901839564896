import { Icon, useBoolean } from '@plugsurfing/plugsurfing-design';
import EditModal from 'components/design-elements/CdEditableCardWithoutControlsV2/EditModal';
import { t } from 'i18n';
import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { p3Theme } from 'styles/theme';
import type { PrivilegeCheck } from 'utils/roles';
import { CdCard, CdCardProps, CdLinkButton, CdTooltip } from '..';
import styles from './CdEditableCardWithoutControlsV2.module.scss';

export interface CdEditableCardWithoutControlsV2Ref {
  closeModal: () => void;
}
export interface CdEditableCardWithoutControlsV2Props extends CdCardProps, PrivilegeCheck {
  editComponent: JSX.Element | null;
  editComponentTitle: string;
  displayComponent: JSX.Element | undefined;
  disabled?: boolean;
  isInfo?: boolean;
  infoMessage?: string;
  loading?: boolean;
  variantOnEdit?: 'card' | 'modal';
}

const CdEditableCardWithoutControlsV2 = forwardRef<
  CdEditableCardWithoutControlsV2Ref,
  CdEditableCardWithoutControlsV2Props
>((props, ref) => {
  const {
    editComponent,
    editComponentTitle,
    displayComponent,
    loading,
    header,
    headerSize,
    allowedPrivileges,
    allowedModules,
    disabled,
    isInfo,
    infoMessage,
    variantOnEdit = 'modal',
    ...restProps
  } = props;

  const [showEditView, setShowEditView] = useBoolean(false);

  useImperativeHandle(ref, () => ({
    closeModal() {
      setShowEditView.off();
    },
  }));

  const handleClick = useCallback(() => {
    variantOnEdit === 'modal' ? setShowEditView.on() : setShowEditView.toggle();
  }, [setShowEditView, variantOnEdit]);

  return (
    <CdCard
      {...restProps}
      header={header}
      headerSize={headerSize}
      loading={loading}
      topRight={
        <div className={styles.topButtonContainer}>
          {isInfo ? (
            <CdTooltip label={infoMessage} placement="right">
              <Icon cursor="pointer" name="Info" size="s" color={p3Theme.colors.interactive.primary} />
            </CdTooltip>
          ) : editComponent ? (
            <CdLinkButton
              whiteSpace="break-spaces"
              rightIcon={variantOnEdit === 'card' && showEditView ? undefined : 'Edit'}
              isDisabled={disabled || (showEditView && variantOnEdit !== 'card')}
              onClick={handleClick}
              label={variantOnEdit === 'card' && showEditView ? undefined : t('edit')}
              allowedPrivileges={allowedPrivileges}
              allowedModules={allowedModules}
            />
          ) : null}
        </div>
      }
    >
      {!loading && variantOnEdit === 'modal' ? (
        <>
          {displayComponent}
          <EditModal
            isOpen={showEditView}
            title={editComponentTitle}
            onClose={setShowEditView.off}
            modalBody={editComponent}
          />
        </>
      ) : !loading && variantOnEdit === 'card' ? (
        <>{showEditView ? editComponent : displayComponent}</>
      ) : null}
    </CdCard>
  );
});

export default CdEditableCardWithoutControlsV2;
