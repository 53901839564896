import { Box, Flex } from '@plugsurfing/plugsurfing-design';
import { CARD_DOM_ATTRIBUTE } from 'components/design-elements/CdCard';
import { ReactNode, useId } from 'react';
import { p3Theme } from 'styles/theme';

export interface CdKeyValueProps {
  label: ReactNode;
  verticalAlign?: 'middle' | 'top';
  widthLabel?: number;
  widthChildren?: number;
  indentedLabel?: boolean;
  className?: string;
  containerClassName?: string;
  variant?: 'primary' | 'huge' | 'secondary';
  children?: ReactNode;
}

export default function CdKeyValue({
  label,
  children,
  widthLabel = 5,
  widthChildren = 11,
  verticalAlign = 'top',
  indentedLabel = false,
  className = '',
  containerClassName = '',
  variant = 'primary',
}: CdKeyValueProps) {
  const id = useId();

  return (
    <Flex
      className={className}
      flexDir={variant === 'huge' ? 'column' : 'row'}
      pl={indentedLabel ? 'component.m' : 0}
      {...(variant === 'huge'
        ? { ...p3Theme.textStyles.label.m, gap: 'component.xs' }
        : { gap: 'component.s', alignItems: verticalAlign === 'top' ? 'start' : 'center' })}
      sx={{
        '&:not(:last-child)': { mb: variant === 'huge' ? 'component.m' : 'component.s' },
        // The key-value component should not have margin bottom when used as a direct child of the card component,
        // because it has gap of 16px between its children.
        [`[${CARD_DOM_ATTRIBUTE}] > &`]: { mb: 0 },
      }}
    >
      <Box
        id={id}
        as="dt"
        pl={indentedLabel ? 'layout.s' : 0}
        {...(variant === 'huge' ? { fontWeight: '700' } : { w: '100px', color: 'text.tertiary', flexGrow: widthLabel })}
      >
        {label}
      </Box>
      <Box
        as="dd"
        flexBasis={0}
        flexGrow={1}
        className={containerClassName}
        color={variant === 'secondary' ? p3Theme.colors.text.secondary : undefined}
        style={{ flexGrow: widthChildren }}
        overflowWrap="anywhere"
        whiteSpace="pre-line"
        aria-labelledby={id}
        _empty={{ _after: { content: '"—"', display: 'inline', color: 'disabled.disabled-secondary' } }}
      >
        {children}
      </Box>
    </Flex>
  );
}
