import { combineEpics } from 'redux-observable';
import OrganizationOrdersService from '../../services/OrganizationOrdersService';
import { mergeMapEpic } from '../utils';
import * as actions from './actions';

export default combineEpics(
  mergeMapEpic({
    asyncAction: actions.fetchOrganizationOrder,
    api: ({ payload }) => OrganizationOrdersService.getOrderByIdUsingGET(payload),
  }),
);
