import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@plugsurfing/plugsurfing-design';
import CdButton, { CdButtonProps } from 'components/design-elements/CdButton';
import { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CdConfirmPopupState {
  isOpen: boolean;
  proceed: (value: unknown) => void;
  cancel: (value: unknown) => void;
  titleText?: string;
  confirmButtonVariant?: CdButtonProps['variant'];
  confirmLabelText?: string;
  cancelLabelText?: string;
  bodyText?: string;
}

interface CdConfirmPopupProps {
  children?: ReactNode;
  size?: ModalProps['size'];
}

export interface ConfirmModalProps {
  titleText: string;
  confirmButtonVariant?: CdButtonProps['variant'];
  confirmButtonText: string;
  cancelButtonText: string;
  bodyText?: string;
}

export interface CdConfirmPopupRef {
  isConfirmed: (prompt?: boolean, modalProps?: ConfirmModalProps) => Promise<boolean>;
}

export const CdConfirmPopup = forwardRef<CdConfirmPopupRef, CdConfirmPopupProps>(({ size = 'S', children }, ref) => {
  const [confirm, setConfirm] = useState<CdConfirmPopupState>({
    isOpen: false,
    proceed: () => {
      return;
    },
    cancel: () => {
      return;
    },
  });
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    isConfirmed(prompt, modalProps) {
      const { titleText, bodyText, confirmButtonText, cancelButtonText, confirmButtonVariant } = modalProps || {};
      const promise = new Promise((resolve, reject) => {
        setConfirm({
          isOpen: true,
          proceed: resolve,
          cancel: reject,
          titleText: titleText || undefined,
          confirmButtonVariant: confirmButtonVariant || undefined,
          confirmLabelText: confirmButtonText || undefined,
          cancelLabelText: cancelButtonText || undefined,
          bodyText: bodyText || undefined,
        });
      });
      return promise.then(
        () => {
          setConfirm({ ...confirm, isOpen: false });
          return true;
        },
        () => {
          setConfirm({ ...confirm, isOpen: false });
          return false;
        },
      );
    },
  }));

  return (
    <>
      <Modal isOpen={confirm.isOpen} size={size} onClose={confirm.cancel as () => object}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{confirm.titleText ? confirm.titleText : t('confirm')}</ModalHeader>
          <ModalBody>{confirm?.bodyText || children}</ModalBody>
          <ModalFooter>
            <CdButton variant="secondary" onClick={confirm.cancel}>
              {confirm?.cancelLabelText || t('no')}
            </CdButton>
            <CdButton variant={confirm?.confirmButtonVariant || 'primary'} onClick={confirm.proceed}>
              {confirm?.confirmLabelText || t('yes')}
            </CdButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
