import { LocalesKey } from 'i18n';

interface LocalizedEnum {
  [key: string]: LocalesKey;
}

export const SupportedCommandsEnum: LocalizedEnum = {
  CLEAR_AUTHORIZATION_CACHE: 'CLEAR_AUTHORIZATION_CACHE',
  GET_DIAGNOSTICS: 'GET_DIAGNOSTICS',
  CONNECTOR_ON: 'CONNECTOR_ON',
  CONNECTOR_OFF: 'CONNECTOR_OFF',
  HARD_RESET: 'HARD_RESET',
  SOFT_RESET: 'SOFT_RESET',
  SEND_AUTHORIZATION_LIST: 'SEND_AUTHORIZATION_LIST',
  CLEAR_AUTHORIZATION_LIST: 'CLEAR_AUTHORIZATION_LIST',
  UNLOCK_CONNECTOR: 'UNLOCK_CONNECTOR',
  UPDATE_FIRMWARE: 'UPDATE_FIRMWARE',
  GET_CONFIGURATION: 'GET_CONFIGURATION',
};

export const capabilitiesEnum = [
  'CHARGING_PROFILE_CAPABLE',
  'CREDIT_CARD_PAYABLE',
  'REMOTE_START_STOP_CAPABLE',
  'RESERVABLE',
  'RFID_READER',
  'UNLOCK_CAPABLE',
  'PLUG_AND_CHARGE_CAPABLE',
] as const;

export const GatewayProtocolEnum: LocalizedEnum = {
  OCPP_SOAP_1_5: 'OCPP_SOAP_1_5',
  OCPP_SOAP_1_6: 'OCPP_SOAP_1_6',
  OCPP_WEB_SOCKET_1_6: 'OCPP_WEB_SOCKET_1_6',
};

export const ConnectorAdminStatusEnum: LocalizedEnum = {
  OPERATIONAL: 'OPERATIONAL',
  OUT_OF_ORDER: 'OUT_OF_ORDER',
};

export const ChargePointAdminStatusEnum: LocalizedEnum = {
  IN_TESTING: 'IN_TESTING',
  DISABLED: 'DISABLED',
  IN_OPERATION: 'IN_OPERATION',
  IN_WAREHOUSE: 'IN_WAREHOUSE',
  ORDERED: 'ORDERED',
  PENDING_DELETE: 'PENDING_DELETE',
  TESTING_OK: 'TESTING_OK',
  AWAITING_REPAIR: 'AWAITING_REPAIR',
};

export const ChargePointStatusEnum: LocalizedEnum = {
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Unavailable',
  FAULTED: 'Faulted',
  OCCUPIED: 'Occupied',
  RESERVED: 'Reserved',
};

export const ConnectorStatusEnum: LocalizedEnum = {
  AVAILABLE: 'Available',
  PREPARING: 'Preparing',
  CHARGING: 'Charging',
  SUSPENDED_EVSE: 'SuspendedEVSE',
  SUSPENDED_EV: 'SuspendedEV',
  FINISHING: 'Finishing',
  UNAVAILABLE: 'Unavailable',
  FAULTED: 'Faulted',
  OCCUPIED: 'Occupied',
  RESERVED: 'Reserved',
  UNKNOWN: 'Unknown',
};

export const ModeEnum: LocalizedEnum = {
  MODE1: 'MODE1',
  MODE2: 'MODE2',
  MODE3: 'MODE3',
  MODE4: 'MODE4',
};

export const PublishingChannelsEnum: LocalizedEnum = {
  CHARGE_AND_DRIVE_CONNECT_API: 'CHARGE_AND_DRIVE_CONNECT_API',
  CHARGE_AND_DRIVE_MAP: 'CHARGE_AND_DRIVE_MAP',
  CHARGE_AND_DRIVE_ADHOC: 'CHARGE_AND_DRIVE_ADHOC',
  PLUGSURFING: 'PLUGSURFING',
  HUBJECT: 'HUBJECT',
  EASY_PARK: 'EASY_PARK',
  NEXTGREENCAR: 'NEXTGREENCAR',
  EMPARK: 'EMPARK',
  FORTUM_SPRING: 'FORTUM_SPRING',
  MARUTI: 'MARUTI',
  NOBIL: 'NOBIL',
  WATTS_UP: 'WATTS_UP',
  FORTUM_SHOWROOM: 'FORTUM_SHOWROOM',
  STOCKHOLM_EXERGI: 'STOCKHOLM_EXERGI',
  ALIZE_MAP: 'ALIZE_MAP',
  NEWMOTION: 'NEWMOTION',
  ENEL: 'ENEL',
  HAYDON_ME: 'HAYDON_ME',
  GREENCHARGE: 'GREENCHARGE',
  ZET: 'ZET',
  ESMART: 'ESMART',
  DCS: 'DCS',
  VG: 'VG',
  BONNET: 'BONNET',
  FORTUM_SPRING_SMART_CHARGING: 'FORTUM_SPRING_SMART_CHARGING',
  RECHARGE_MAP: 'RECHARGE_MAP',
  KOPLE_MAP: 'KOPLE_MAP',
  HAS_TO_BE: 'HAS_TO_BE',
  TEST_CHANNEL: 'TEST_CHANNEL',
  NIO: 'NIO',
  NEF: 'NEF',
  NAF: 'NAF',
  ECO_MOVEMENT: 'ECO_MOVEMENT',
  MOVE_MOBILITY: 'MOVE_MOBILITY',
  AGL_ENERGY: 'AGL_ENERGY',
  BE_CHARGE: 'BE_CHARGE',
  FORTUM_INDIA: 'FORTUM_INDIA',
  ENEL_X: 'ENEL_X',
  ST1: 'ST1',
  PUBLISHING_CHANNEL_001: 'PUBLISHING_CHANNEL_001',
  PUBLISHING_CHANNEL_002: 'PUBLISHING_CHANNEL_002',
  PUBLISHING_CHANNEL_003: 'PUBLISHING_CHANNEL_003',
  PUBLISHING_CHANNEL_004: 'PUBLISHING_CHANNEL_004',
  PUBLISHING_CHANNEL_005: 'PUBLISHING_CHANNEL_005',
  PUBLISHING_CHANNEL_006: 'PUBLISHING_CHANNEL_006',
  PUBLISHING_CHANNEL_007: 'PUBLISHING_CHANNEL_007',
  PUBLISHING_CHANNEL_008: 'PUBLISHING_CHANNEL_008',
  PUBLISHING_CHANNEL_009: 'PUBLISHING_CHANNEL_009',
  PUBLISHING_CHANNEL_010: 'PUBLISHING_CHANNEL_010',
  PUBLISHING_CHANNEL_011: 'PUBLISHING_CHANNEL_011',
  PUBLISHING_CHANNEL_012: 'PUBLISHING_CHANNEL_012',
  PUBLISHING_CHANNEL_013: 'PUBLISHING_CHANNEL_013',
  PUBLISHING_CHANNEL_014: 'PUBLISHING_CHANNEL_014',
  PUBLISHING_CHANNEL_015: 'PUBLISHING_CHANNEL_015',
  PUBLISHING_CHANNEL_016: 'PUBLISHING_CHANNEL_016',
  PUBLISHING_CHANNEL_017: 'PUBLISHING_CHANNEL_017',
  PUBLISHING_CHANNEL_018: 'PUBLISHING_CHANNEL_018',
  PUBLISHING_CHANNEL_019: 'PUBLISHING_CHANNEL_019',
  PUBLISHING_CHANNEL_020: 'PUBLISHING_CHANNEL_020',
  PUBLISHING_CHANNEL_021: 'PUBLISHING_CHANNEL_021',
  PUBLISHING_CHANNEL_022: 'PUBLISHING_CHANNEL_022',
  PUBLISHING_CHANNEL_023: 'PUBLISHING_CHANNEL_023',
  PUBLISHING_CHANNEL_024: 'PUBLISHING_CHANNEL_024',
  PUBLISHING_CHANNEL_025: 'PUBLISHING_CHANNEL_025',
  PUBLISHING_CHANNEL_026: 'PUBLISHING_CHANNEL_026',
  PUBLISHING_CHANNEL_027: 'PUBLISHING_CHANNEL_027',
  PUBLISHING_CHANNEL_028: 'PUBLISHING_CHANNEL_028',
  PUBLISHING_CHANNEL_029: 'PUBLISHING_CHANNEL_029',
  PUBLISHING_CHANNEL_030: 'PUBLISHING_CHANNEL_030',
  PUBLISHING_CHANNEL_031: 'PUBLISHING_CHANNEL_031',
  PUBLISHING_CHANNEL_032: 'PUBLISHING_CHANNEL_032',
  PUBLISHING_CHANNEL_033: 'PUBLISHING_CHANNEL_033',
  PUBLISHING_CHANNEL_034: 'PUBLISHING_CHANNEL_034',
  PUBLISHING_CHANNEL_035: 'PUBLISHING_CHANNEL_035',
  PUBLISHING_CHANNEL_036: 'PUBLISHING_CHANNEL_036',
  PUBLISHING_CHANNEL_037: 'PUBLISHING_CHANNEL_037',
  PUBLISHING_CHANNEL_038: 'PUBLISHING_CHANNEL_038',
  PUBLISHING_CHANNEL_039: 'PUBLISHING_CHANNEL_039',
  PUBLISHING_CHANNEL_040: 'PUBLISHING_CHANNEL_040',
  PUBLISHING_CHANNEL_041: 'PUBLISHING_CHANNEL_041',
  PUBLISHING_CHANNEL_042: 'PUBLISHING_CHANNEL_042',
  PUBLISHING_CHANNEL_043: 'PUBLISHING_CHANNEL_043',
  PUBLISHING_CHANNEL_044: 'PUBLISHING_CHANNEL_044',
  PUBLISHING_CHANNEL_045: 'PUBLISHING_CHANNEL_045',
  PUBLISHING_CHANNEL_046: 'PUBLISHING_CHANNEL_046',
  PUBLISHING_CHANNEL_047: 'PUBLISHING_CHANNEL_047',
  PUBLISHING_CHANNEL_048: 'PUBLISHING_CHANNEL_048',
  PUBLISHING_CHANNEL_049: 'PUBLISHING_CHANNEL_049',
  PUBLISHING_CHANNEL_050: 'PUBLISHING_CHANNEL_050',
  PUBLISHING_CHANNEL_051: 'PUBLISHING_CHANNEL_051',
  PUBLISHING_CHANNEL_052: 'PUBLISHING_CHANNEL_052',
  PUBLISHING_CHANNEL_053: 'PUBLISHING_CHANNEL_053',
  PUBLISHING_CHANNEL_054: 'PUBLISHING_CHANNEL_054',
  PUBLISHING_CHANNEL_055: 'PUBLISHING_CHANNEL_055',
  PUBLISHING_CHANNEL_056: 'PUBLISHING_CHANNEL_056',
  PUBLISHING_CHANNEL_057: 'PUBLISHING_CHANNEL_057',
  PUBLISHING_CHANNEL_058: 'PUBLISHING_CHANNEL_058',
  PUBLISHING_CHANNEL_059: 'PUBLISHING_CHANNEL_059',
  PUBLISHING_CHANNEL_060: 'PUBLISHING_CHANNEL_060',
};

export const PublishingChannelsUpdateTypeEnum: LocalizedEnum = {
  ADD_PUBLISHING_CHANNELS: 'ADD_PUBLISHING_CHANNELS',
  REMOVE_PUBLISHING_CHANNELS: 'REMOVE_PUBLISHING_CHANNELS',
  REPLACE_PUBLISHING_CHANNELS: 'REPLACE_PUBLISHING_CHANNELS',
};

export const FirmwareStatusEnum: LocalizedEnum = {
  IN_TESTING: 'IN_TESTING',
  APPROVED: 'APPROVED',
  RETIRED: 'RETIRED',
};

export const TimeUnitsEnum: LocalizedEnum = {
  SECONDS: 'SECONDS',
  MINUTES: 'MINUTES',
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
};

export const ChargePointLogEventType: LocalizedEnum = {
  AUTHORIZATION: 'AUTHORIZATION',
  REMOTE_START_CHARGING: 'REMOTE_START_CHARGING',
  REMOTE_STOP_CHARGING: 'REMOTE_STOP_CHARGING',
  START_CHARGING: 'START_CHARGING',
  RESENT_START_CHARGING: 'RESENT_START_CHARGING',
  STOP_CHARGING: 'STOP_CHARGING',
  PAYMENT: 'PAYMENT',
  RESERVE_NOW: 'RESERVE_NOW',
  CANCEL_RESERVATION: 'CANCEL_RESERVATION',
};

export const ConnectorGroupsUpdateTypeEnum: LocalizedEnum = {
  ADD_CONNECTOR_GROUPS: 'ADD_CONNECTOR_GROUPS',
  REMOVE_CONNECTOR_GROUPS: 'REMOVE_CONNECTOR_GROUPS',
  REPLACE_CONNECTOR_GROUPS: 'REPLACE_CONNECTOR_GROUPS',
};

export const PaymentMethods: LocalizedEnum = {
  CREDIT_CARD: 'CREDIT_CARD',
  INVOICE: 'INVOICE',
  PREPAID: 'PREPAID',
  WRITE_OFF: 'WRITE_OFF',
  'pre-paid': 'PREPAID',
  'credit-card': 'CREDIT_CARD',
  'sepa-debit': 'SEPA',
  billing: 'BILLING',
  external: 'EXTERNAL',
  invoice: 'INVOICE',
  BILLING: 'BILLING',
  EXTERNAL: 'EXTERNAL',
};

export const UnlockConnectorEnum: LocalizedEnum = {
  UNLOCKFAILED: 'UNLOCKFAILED',
  UNLOCKED: 'UNLOCKED',
  NOTSUPPORTED: 'NOTSUPPORTED',
};

export const ModulesAbbreviationEnum = {
  EMP: 'EMP',
  CPO: 'CPO',
  AO: 'AO',
  MFR: 'MFR',
  LO: 'LO',
  FLEET: 'FLEET',
  PUBLIC: 'PUBLIC',
  EXTERNAL_CPO: 'EXTERNAL_CPO',
  EXTERNAL_EMP: 'EXTERNAL_EMP',
  UNKNOWN: 'UNKNOWN',
} as const;

export const ImageTypeEnum: LocalizedEnum = {
  SQUARE: 'SQUARE',
  PORTRAIT: 'PORTRAIT',
  LANDSCAPE: 'LANDSCAPE',
};

export const RoleEnum: LocalizedEnum = {
  administrator: 'ADMINISTRATOR',
  customer_service: 'CUSTOMER_SERVICE',
  finance_analyst: 'FINANCE_ANALYST',
  operations_manager: 'OPERATIONS_MANAGER',
  general_manager: 'GENERAL_MANAGER',
  personal_data_manager: 'PERSONAL_DATA_MANAGER',
  charge_point_site_owner: 'CHARGE_POINT_SITE_OWNER',
  charger_manufacturer: 'CHARGER_MANUFACTURER',
  b2b_manager: 'B2B_MANAGER',
  platform_administrator: 'PLATFORM_ADMINISTRATOR',
  first_real_custom_role: 'FIRST_REAL_CUSTOM_ROLE',
  product_order_manager: 'PRODUCT_ORDER_MANAGER',
  customer_service_lite: 'CUSTOMER_SERVICE_LITE',
  finance_admin: 'FINANCE_ADMIN',
  supervisor: 'SUPERVISOR',
  drive_api_manager: 'DRIVE_API_MANAGER',
  drive_api_user: 'DRIVE_API_USER',
  roaming_manager: 'ROAMING_MANAGER',
  network_manager: 'NETWORK_MANAGER',
  fleet_ck_reservations: 'FLEET_CK_RESERVATIONS',
};

// not used?
/*
export const PriceModelEnum: LocalizedEnum = {
  H: 'H',
  KWH: 'KWH',
  MIN: 'MIN',
};

export const PeriodEnum: LocalizedEnum = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const PriceTypeEnum: LocalizedEnum = {
  ABSOLUTE: 'ABSOLUTE',
  RELATIVE: 'RELATIVE',
};

export const ExportOptionEnum: LocalizedEnum = {
  SESSION: 'SESSION',
  TRANSACTION: 'TRANSACTION',
};
*/
