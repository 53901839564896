import { JsonSchema, PayloadObjectResponse, PrivateCustomerResponse } from '@plugsurfing/cdm-api-client';
import { Action } from 'redux';
import { ActionsObservable, StateObservable, combineEpics, ofType } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import UserService from 'services/UserServiceWrapper';
import OrganizationOrdersService from '../../services/OrganizationOrdersService';
import * as organizationActions from '../organization-orders/actions';
import { RootState } from '../reducers';
import * as usersActions from '../users/actions';
import { mergeMapEpic } from '../utils';
import * as actions from './actions';
import { getCustomerCustomFieldsKey } from './reducers';

const fetchSchemaForOrder = (
  action$: ActionsObservable<Action & { payload: { result: PayloadObjectResponse } }>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(organizationActions.fetchOrganizationOrder.done.type),
    filter(
      ({
        payload: {
          result: { parentId },
        },
      }) => state$.value.schemas.orderById[parentId] === undefined,
    ),
    mergeMap(action => [actions.fetchOrderSchema.started(action.payload.result.parentId)]),
  );

const fetchSchemaForUser = (
  action$: ActionsObservable<Action & { payload: { result: PrivateCustomerResponse } }>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(usersActions.fetch.done.type),
    filter(
      ({
        payload: {
          result: {
            organization: { id },
            customerType,
          },
        },
      }) =>
        state$.value.schemas.customerCustomFieldsById[
          getCustomerCustomFieldsKey({
            customerType,
            organizationId: id,
          })
        ] === undefined,
    ),
    mergeMap(action => [
      actions.fetchCustomerCustomFieldsSchema.started({
        organizationId: action.payload.result.organization.id,
        customerType: action.payload.result.customerType,
      }),
    ]),
  );

export default combineEpics(
  fetchSchemaForOrder,
  fetchSchemaForUser,
  mergeMapEpic({
    asyncAction: actions.fetchOrderSchema,
    api: ({ payload }) => OrganizationOrdersService.getSchemaUsingGET(payload) as Promise<JsonSchema>,
    filter: (action, state) => state.value.schemas.orderById[action.payload] === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchCustomerCustomFieldsSchema,
    api: ({ payload: { organizationId } }) => UserService.getSchemaUsingGET1(organizationId),
    filter: (action, state) =>
      state.value.schemas.customerCustomFieldsById[getCustomerCustomFieldsKey(action.payload)] === undefined,
  }),
);
