import { Heading, Image, VStack } from '@plugsurfing/plugsurfing-design';
import type { ReactNode } from 'react';
import { defaultLogotype, p3Theme } from 'styles/theme';

interface LogoHeadingProps {
  children: ReactNode;
  isSetupMFA?: boolean;
}

export const LogoHeading = ({ children, isSetupMFA = false }: LogoHeadingProps) => {
  return (
    <VStack pb={isSetupMFA ? 'component.s' : 'component.2xl'}>
      <Image src={defaultLogotype} w="48px" />
      <Heading textAlign="center" as="h1" {...p3Theme.textStyles.heading.xs}>
        {children}
      </Heading>
    </VStack>
  );
};

export default LogoHeading;
