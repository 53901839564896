import { combineEpics } from 'redux-observable';
import { mergeMapEpic } from 'redux/utils';
import ConnectionProfileService from 'services/ConnectionProfileService';
import * as actions from './actions';

export default combineEpics(
  mergeMapEpic({
    api: action => ConnectionProfileService.findConnectionProfilesUsingGET(action.payload ? action.payload : undefined),
    asyncAction: actions.fetchByOrganization,
    filter: (action, state) =>
      action.payload
        ? state.value.connectionProfiles.byOrgId[action.payload] === undefined
        : Object.keys(state.value.connectionProfiles.byId).length === 0,
  }),
  mergeMapEpic({
    api: action => ConnectionProfileService.findConnectionProfileByIdUsingGET(action.payload),
    asyncAction: actions.fetch,
    filter: (action, state) => state.value.connectionProfiles.byId[action.payload] === undefined,
  }),
);
