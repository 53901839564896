import { ChargingKeyType, ChargingKeyWithDebt } from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { ORGANIZATION } from 'models/CRMApiV2Models';
import { isOrganization } from 'models/functions';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export interface State {
  keys: {
    byId: {
      [key: string]: ChargingKeyWithDebt;
    };
    byOrgId: {
      [key: string]: string[];
    };
    byUserId: {
      [key: string]: string[];
    };
  };
  types: {
    byId: {
      [key: string]: ChargingKeyType;
    };
  };
}

export const initialState: State = {
  keys: { byId: {}, byOrgId: {}, byUserId: {} },
  types: { byId: {} },
};

export default function (state: State = initialState, action: Action) {
  return produce(state, draft => {
    if (isType(action, actions.fetchByOrganization.done)) {
      const { result, params } = action.payload;
      draft.keys.byOrgId[params] = [];
      result.forEach(key => {
        draft.keys.byId[key.id] = key;
        draft.keys.byOrgId[params].push(key.id);
      });
    }

    if (isType(action, actions.fetchByUser.done)) {
      const { result, params } = action.payload;
      draft.keys.byUserId[params] = [];
      result.forEach(key => {
        if (draft.keys.byId[key.id] === undefined) {
          draft.keys.byId[key.id] = key;
        }
        draft.keys.byUserId[params].push(key.id);
      });
    }

    if (isType(action, actions.fetch.done)) {
      const { result } = action.payload;
      if (result.owner.type === ORGANIZATION) {
        draft.keys.byOrgId[result.owner.id] = draft.keys.byOrgId[result.owner.id] || [];
        if (draft.keys.byOrgId[result.owner.id].indexOf(result.id) === -1) {
          draft.keys.byOrgId[result.owner.id].push(result.id);
        }
      } else {
        draft.keys.byUserId[result.owner.id] = draft.keys.byUserId[result.owner.id] || [];
        if (draft.keys.byUserId[result.owner.id].indexOf(result.id) === -1) {
          draft.keys.byUserId[result.owner.id].push(result.id);
        }
      }
      draft.keys.byId[result.id] = result;
    }

    if (isType(action, actions.fetchTypes.done)) {
      const { result } = action.payload;
      result.forEach(type => (draft.types.byId[type.id] = type));
    }

    if (isType(action, actions.deleteChargingKey.done)) {
      const {
        params: { owner, id },
      } = action.payload;
      delete draft.keys.byId[id];
      if (isOrganization(owner)) {
        draft.keys.byOrgId[owner.id] = draft.keys.byOrgId[owner.id]!.filter(keyId => keyId !== id);
      } else {
        draft.keys.byUserId[owner.id] = draft.keys.byUserId[owner.id]!.filter(keyId => keyId !== id);
      }
    }
  });
}
