import { Image as ImageType, SystemUser } from '@plugsurfing/cdm-api-client';
import { Avatar, Box, Divider, Flex, Icon, Image } from '@plugsurfing/plugsurfing-design';
import { useGetOrganizationImagesUsingGetQuery } from 'cdm-api-client/v2OrganizationsApi';
import { renderMenuItems } from 'components/design-elements/CdMainMenu/CdMainMenu';
import { MainMenuButtonItem } from 'components/design-elements/CdMainMenu/MainMenuItem/MainMenuItem';
import UserMenu from 'components/design-elements/CdTopBar/UserMenu';
import { ROOT } from 'config/links';
import { t } from 'i18n';
import { memo, useContext, useMemo, type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { defaultLogotype } from 'styles/theme';
import { getNameV2 } from 'utils/forms';
import { getImage, getLogo } from 'utils/views';
import { LayoutContainerContext } from 'views/LayoutContainer';
import { MenuItem, MenuItemLabelEnum } from '../MenuItems';

export interface DesktopMainMenuProps {
  items: MenuItem[];
  user?: SystemUser;
  children: ReactNode;
}

const DesktopMainMenu = memo((props: DesktopMainMenuProps) => {
  const { children, items, user } = props;
  const context = useContext(LayoutContainerContext);
  const { showVisible, hideVisible, visible, togglePersistVisible, persistVisible } = context;
  const { logo, isLoading: logoLoading } = useOrganizationLogotype(user?.organization.id || '', !visible);

  const adminMenuItems = useMemo(() => items.filter(item => item.labelKey === MenuItemLabelEnum.ADMIN), [items]);

  const regularMenuItems = useMemo(
    () => items.filter(item => item.labelKey !== MenuItemLabelEnum.ADMIN),
    [items, user],
  );

  return (
    <div>
      <Flex
        flexDir="column"
        as={'nav'}
        bg="background.secondary"
        borderRightColor="border.primary"
        borderRightWidth="1px"
        w={`${context.visible ? context.menuExpandedWidth : context.menuCollapsedWidth}px`}
        h="100vh"
        py="component.2xs"
        onMouseEnter={showVisible}
        onMouseOver={showVisible}
        onMouseLeave={persistVisible ? undefined : hideVisible}
      >
        <Flex h="32px" alignItems="center" mx={visible ? 'component.xs' : 0} px="component.2xs" marginY="component.3xs">
          <Link
            to={ROOT().path}
            onClick={() => {
              hideVisible();
            }}
          >
            {renderBrandLogotype(logo, logoLoading)}
          </Link>
        </Flex>
        <Flex rowGap="component.2xs" flexDir="column" flex="1 1" overflow="auto" p="component.2xs">
          {renderMenuItems(regularMenuItems, user)}
          <Box flex="1" />
          {renderMenuItems(adminMenuItems, user)}
        </Flex>
        <Flex rowGap="component.2xs" flexDir="column" justifySelf="end" px="component.2xs">
          <Divider />
          <UserMenuItem currentUser={user} />
          <Divider />
          <PersistVisibleMenuItem toggle={togglePersistVisible} persist={persistVisible} />
        </Flex>
      </Flex>
      <Flex ml={[0, 0, '60px']}>{children}</Flex>
    </div>
  );
});

const UserMenuItem = ({ currentUser }: { currentUser?: SystemUser }) => (
  <UserMenu
    userName={currentUser ? getNameV2(currentUser) : ''}
    userEmail={currentUser?.email || ''}
    trigger={
      <MainMenuButtonItem
        as="div"
        leftElement={
          <Avatar
            w="icons.l"
            h="icons.l"
            borderRadius="l"
            color="interactive.primary"
            bg="interactive.background"
            name={currentUser?.firstName || ''}
          />
        }
        rightElement={<Icon name="Kebab" color={'text.primary'} size="s" />}
      >
        {currentUser ? getNameV2(currentUser) : ''}
      </MainMenuButtonItem>
    }
  />
);

const PersistVisibleMenuItem = ({ persist, toggle }: { persist: boolean; toggle: () => void }) => (
  <MainMenuButtonItem
    leftElement={<Icon name={persist ? 'CollapseSidebar' : 'OpenSidebar'} size="s" />}
    onClick={toggle}
  >
    <Box>{persist ? t('collapseSidebar') : t('expandSidebar')}</Box>
  </MainMenuButtonItem>
);

const useOrganizationLogotype = (organizationId: string, preferSquare?: boolean) => {
  const { data: organizationImages, isLoading } = useGetOrganizationImagesUsingGetQuery({ organizationId });
  const squareLogo = getLogo(organizationImages?.config.images, ImageType.TypeEnum.SQUARE);
  const landscapeLogo = getLogo(organizationImages?.config.images, ImageType.TypeEnum.LANDSCAPE);

  const image = useMemo(() => {
    if (preferSquare) {
      return squareLogo ? getImage(squareLogo) : landscapeLogo ? getImage(landscapeLogo) : defaultLogotype;
    }
    return landscapeLogo ? getImage(landscapeLogo) : defaultLogotype;
  }, [landscapeLogo, preferSquare, squareLogo]);

  return { logo: image, isLoading };
};

const renderBrandLogotype = (logoPath: string, isLogoLoading: boolean) => {
  if (isLogoLoading) {
    return null;
  }
  return <Image src={logoPath} maxH="40px" />;
};

export default DesktopMainMenu;
