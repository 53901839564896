import { Box, Heading } from '@plugsurfing/plugsurfing-design';
import { MenuItem } from 'components/design-elements/CdMainMenu/MenuItems';

export const LinkPreview = ({ link }: { link: MenuItem }) => {
  return (
    <Box>
      <Heading size="xs" mb="component.s">
        {link.labelKey}
      </Heading>
      Link preview is coming soon!
    </Box>
  );
};
