import { Box, IconButton } from '@plugsurfing/plugsurfing-design';
import CdDropdown from 'components/design-elements/CdDropdown';
import { CdInput } from 'components/design-elements/CdInputFieldV2';
import debounce from 'lodash/debounce';
import upperFirst from 'lodash/upperFirst';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface CdTablePagerProps {
  pageIndex: number;
  pageSize: number;
  maxPageSize?: number;
  hasPrevious: boolean;
  hasNext: boolean;
  totalItems?: number;
  isLoading: boolean;
  onSetPageSize: (size: number) => void;
  onPrev: () => void;
  onNext: () => void;
}

export const PAGE_SIZE_OPTIONS = [25, 100, 200, 'custom'] as const;

export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];

export default function CdTablePager({
  pageIndex,
  pageSize,
  maxPageSize,
  hasPrevious,
  hasNext,
  totalItems,
  isLoading,
  onSetPageSize,
  onPrev,
  onNext,
}: CdTablePagerProps) {
  const { t } = useTranslation();
  const pageSizeInputRef = useRef<HTMLInputElement>(null);
  const filteredPageSizes = useMemo(
    () =>
      maxPageSize === undefined
        ? PAGE_SIZE_OPTIONS
        : PAGE_SIZE_OPTIONS.filter(size => size === 'custom' || size <= maxPageSize),
    [maxPageSize],
  );
  const selectedPageSize = filteredPageSizes.find(option => option === pageSize) ?? 'custom';
  const [customPageSize, setCustomPageSize] = useState(() =>
    selectedPageSize === 'custom' && (maxPageSize === undefined || pageSize <= maxPageSize)
      ? String(pageSize)
      : undefined,
  );
  const pageSizeOptions = useMemo(
    () =>
      filteredPageSizes.map(option => ({
        value: option,
        text: option === 'custom' ? upperFirst(t('custom')) : String(option),
      })),
    [filteredPageSizes, t],
  );
  const handleChangeSelectedPageSize = useCallback(
    (_: unknown, { value }: { value?: (typeof PAGE_SIZE_OPTIONS)[number] }): void => {
      if (typeof value === 'number') {
        onSetPageSize(value);
        setCustomPageSize(undefined);
      }

      if (value === 'custom') {
        setCustomPageSize('');
        window.requestAnimationFrame(() => pageSizeInputRef.current?.focus());
      }
    },
    [onSetPageSize],
  );
  const debouncedOnSetPageSize = useMemo(
    () =>
      debounce(() => {
        const parsed = Number.parseInt(pageSizeInputRef.current?.value ?? '', 10);

        if (!Number.isNaN(parsed) && (maxPageSize === undefined || parsed <= maxPageSize)) {
          onSetPageSize(parsed);
        }
      }, 500),
    [maxPageSize, onSetPageSize],
  );
  const handleChangeCustomPageSize = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCustomPageSize(e.target.value);
      debouncedOnSetPageSize();
    },
    [debouncedOnSetPageSize],
  );
  const handleBlurCustomPageSize = useCallback(
    () => setCustomPageSize(selectedPageSize === 'custom' ? String(pageSize) : undefined),
    [selectedPageSize, pageSize],
  );
  const isCustomPageSizeTooLarge =
    customPageSize !== undefined && maxPageSize !== undefined && Number.parseInt(customPageSize, 10) > maxPageSize;

  useEffect(() => {
    if (maxPageSize !== undefined && pageSize > maxPageSize) {
      onSetPageSize(maxPageSize);
    }
  }, [maxPageSize, onSetPageSize, pageSize]);

  return (
    <>
      <Box alignItems="center" display="flex">
        <CdDropdown
          isPortal
          value={customPageSize !== undefined ? 'custom' : selectedPageSize}
          options={pageSizeOptions}
          onChange={handleChangeSelectedPageSize}
        />
        {customPageSize !== undefined && (
          <CdInput
            ref={pageSizeInputRef}
            type="number"
            placeholder="e.g. 21"
            step={1}
            min={1}
            max={maxPageSize}
            ml="component.s"
            containerProps={{ w: '100px' }}
            value={customPageSize}
            isInvalid={isCustomPageSizeTooLarge}
            onChange={handleChangeCustomPageSize}
            onBlur={handleBlurCustomPageSize}
          />
        )}
        <Box ml="component.m" color="text.secondary">
          {t('itemsPerPage')}
        </Box>
      </Box>

      <Box alignItems="center" display="flex" as="nav" role="navigation" ml="auto">
        {totalItems !== undefined && (
          <Box as="span" flexGrow={1} mr="component.m" color="text.secondary">
            {t('currentPage', {
              first: pageIndex * pageSize + 1,
              last: Math.min(totalItems ?? Number.POSITIVE_INFINITY, (pageIndex + 1) * pageSize),
              total: totalItems,
            })}
          </Box>
        )}
        <IconButton
          aria-label="Prev"
          icon="ChevronLeft"
          mr="component.s"
          size="S"
          variant="secondary"
          isDisabled={!hasPrevious || isLoading || pageIndex === 0}
          onClick={onPrev}
        />
        <IconButton
          aria-label="Next"
          icon="ChevronRight"
          size="S"
          variant="secondary"
          isDisabled={!hasNext || isLoading || (totalItems !== undefined && (pageIndex + 1) * pageSize >= totalItems)}
          onClick={onNext}
        />
      </Box>
    </>
  );
}
