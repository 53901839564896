import {
  ChargePointMessageRequest,
  ChargePointSearchRequest,
  PageResponseChargePointSearchResult,
  PageResponseExternalEvseHeaderView,
  PagingCursor,
} from '@plugsurfing/cdm-api-client';
import CDMService from './CDMServiceV2';

export interface ExternalChargePointSearchRequest {
  empOrgIds?: string[];
  capabilities: string[];
  cpoOrgId?: number;
  cpoExternalId?: string;
  cpoName?: string;
  dataSource?: string;
  streetAddress?: string;
  city?: string;
  country?: string;
  deleted?: boolean;
  evseId?: string;
  isPrivate?: boolean;
  status: string[];
  uidExternal?: string;
  geoLat?: number;
  geoLon?: number;
  geoDistanceInMeters?: number;
  lastUpdatedFrom?: number;
  lastUpdatedTo?: number;
  firstReceivedAtFrom?: number;
  firstReceivedAtTo?: number;
  lastReceivedAtFrom?: number;
  lastReceivedAtTo?: number;
}

export interface ChargingStationRequest {
  searchRequest: ChargePointSearchRequest;
  assetName?: string;
  assetLabel?: string;
  assetLabelNonExact?: string;
  externalId?: string;
  online?: boolean;
}

export default {
  ...CDMService.chargePointControllerClient,
  ...CDMService.chargePointClient,
  ...CDMService.chargePointBulkOperationsClient,
  ...CDMService.chargePointBulkTemplateV2Client,
  ...CDMService.chargePointBulkTemplateClient,
  ...CDMService.externalChargePointClient,
  ...CDMService.externalConnectorControllerApi,
  createFetchOcppLogEntries:
    (chargePointId: string) =>
    (
      {
        operations = [],
        ...request
      }: Omit<ChargePointMessageRequest, 'operations'> & { operations: string[] | string },
      count: number,
      {
        sortField,
        sortFieldCursor,
        sortFieldSortOrder,
        idField,
        idFieldCursor,
        idFieldSortOrder,
      }: Partial<PagingCursor> = {},
      fetchPrevious: boolean,
    ) => {
      const parsedOperations = (typeof operations === 'string' ? [operations] : operations).map(s => s.toUpperCase());
      return CDMService.chargePointControllerClient.fetchOcppLogEntriesUsingPOST(
        chargePointId,
        // @ts-expect-error
        { operations: parsedOperations, ...request },
        count,
        fetchPrevious,
        sortField,
        sortFieldCursor as any,
        sortFieldSortOrder,
        idField,
        idFieldCursor,
        idFieldSortOrder,
      );
    },
  searchExternalChargingStations: (
    request: ExternalChargePointSearchRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious?: boolean,
  ): Promise<PageResponseExternalEvseHeaderView> => {
    const { idField, idFieldCursor, idFieldSortOrder, sortField, sortFieldCursor, sortFieldSortOrder } = cursor || {
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
    };
    const {
      empOrgIds,
      cpoOrgId,
      cpoExternalId,
      dataSource,
      streetAddress,
      city,
      country,
      deleted,
      evseId,
      cpoName,
      isPrivate,
      capabilities,
      status,
      uidExternal,
      geoLat,
      geoLon,
      geoDistanceInMeters,
      lastUpdatedFrom,
      lastUpdatedTo,
      firstReceivedAtFrom,
      firstReceivedAtTo,
      lastReceivedAtFrom,
      lastReceivedAtTo,
    } = request;
    return CDMService.externalChargePointClient.searchExternalEvsesUsingGET(
      empOrgIds,
      evseId ? [evseId] : undefined,
      capabilities,
      status,
      cpoOrgId,
      cpoExternalId,
      cpoName,
      dataSource,
      streetAddress,
      city,
      country,
      deleted,
      isPrivate,
      undefined,
      geoLat,
      geoLon,
      geoDistanceInMeters,
      lastUpdatedFrom,
      lastUpdatedTo,
      firstReceivedAtFrom,
      firstReceivedAtTo,
      lastReceivedAtFrom,
      lastReceivedAtTo,
      uidExternal,
      fetchPrevious || false,
      count || 20,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
    );
  },
  search: (
    request: ChargingStationRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious?: boolean,
  ): Promise<PageResponseChargePointSearchResult> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };

    const { searchRequest, assetLabel, assetLabelNonExact, assetName, externalId, online } = request;
    return CDMService.chargePointControllerClient.searchChargePointsElasticUsingPOST(
      searchRequest,
      assetName,
      assetLabel,
      assetLabelNonExact,
      externalId,
      online,
      fetchPrevious,
      count || 20,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
    );
  },
};
