import Link, { StaticLinkProps } from 'models/Link';
import { OrganizationModule } from 'models/organization';
import { stringify } from 'query-string';
import {
  CHARGEPOINTCOMMANDGETDIAGNOSTICS,
  CHARGEPOINTFIRMWAREREAD,
  CHARGEPOINTLOGREAD,
  CHARGEPOINTMODELREAD,
  CHARGEPOINTREAD,
  CHARGEPOINTSITEREAD,
  CONNECTORGROUPREAD,
  CONNECTORPRICECREATE,
  CONNECTORPRICEREAD,
  ERRORSCENARIOREAD,
  ERRORSCENARIOWRITE,
  EXTERNALCHARGEPOINTREAD,
  EXTERNALLOCATIONREAD,
  REPORTEDCHARGERPROBLEMREAD,
  SMARTCHARGINGCREATE,
} from 'utils/roles';
import { ExternalLocationListFilterSettings } from 'views/chargers/LocationList/ExternalLocationListView/ExternalLocationListView';
import { InternalLocationListFilterSettings } from 'views/chargers/LocationList/InternalLocationListView/InternalLocationListView';
import { DynamicLinkProps, DynamicLinkPropsV2, ROOT, dynamicLinkParams } from './common';

export const CHARGERS = () =>
  Link.static({
    nameLocaleKey: 'chargers',
    pathPart: 'chargers',
    parent: ROOT(),
    allowedPrivileges: [CHARGEPOINTSITEREAD, EXTERNALCHARGEPOINTREAD, REPORTEDCHARGERPROBLEMREAD],
  });

export const CHARGE_POINTS = () =>
  Link.static({
    nameLocaleKey: 'chargingStations',
    pathPart: 'charge-points',
    parent: CHARGERS(),
  });

export const CHARGE_POINT_MODELS = () =>
  Link.static({
    nameLocaleKey: 'models',
    pathPart: 'charge-point-models',
    parent: CHARGERS(),
  });

export const CHARGE_POINT_SITES_BASE = () =>
  Link.static({
    nameLocaleKey: 'locations',
    pathPart: 'chargesites',
    parent: CHARGERS(),
  });

export const CHARGE_POINT_SITES = () => new Link(CHARGE_POINT_SITES_BASE());

interface ChargingStationsQueryParams {
  tab?: 'internal' | 'external';
}
export const CHARGING_STATIONS = (queryParams?: ChargingStationsQueryParams) =>
  Link.static({
    nameLocaleKey: 'chargingStations',
    pathPart: `charging-stations${queryParams ? `?${stringify(queryParams)}` : ''}`,
    parent: CHARGERS(),
    allowedPrivileges: [CHARGEPOINTREAD],
  });

export const CHARGING_STATIONS_ERRORS = () =>
  Link.static({
    nameLocaleKey: 'errors',
    pathPart: 'errors-management',
    parent: CHARGERS(),
    allowedPrivileges: [ERRORSCENARIOREAD],
  });

export const errorScenarioLink = (errorScenario?: DynamicLinkPropsV2) =>
  new Link({
    name: errorScenario?.name || '',
    pathPart: errorScenario?.id || ':errorScenarioId',
    parent: CHARGING_STATIONS_ERRORS(),
    allowedPrivileges: [ERRORSCENARIOREAD],
  });

export const CHARGING_STATIONS_ERROR_SETTINGS = () =>
  Link.static({
    nameLocaleKey: 'errorSettings',
    pathPart: 'settings',
    parent: CHARGING_STATIONS_ERRORS(),
    allowedPrivileges: [ERRORSCENARIOREAD],
  });

export const errorScenarioSettingsCreate = (errorScenario?: DynamicLinkPropsV2) =>
  Link.static({
    nameLocaleKey: 'createErrorSetting',
    pathPart: 'settings',
    parent: errorScenarioLink(errorScenario),
    allowedPrivileges: [ERRORSCENARIOWRITE],
    allowedModules: [OrganizationModule.AbbreviationEnum.CPO],
  });

export const errorScenarioSettingsEdit = (errorScenarioSettings?: DynamicLinkPropsV2) =>
  Link.static({
    nameLocaleKey: 'editErrorSetting',
    pathPart: errorScenarioSettings?.id ?? ':errorScenarioSettingsId',
    parent: CHARGING_STATIONS_ERROR_SETTINGS(),
    allowedPrivileges: [ERRORSCENARIOWRITE],
    allowedModules: [OrganizationModule.AbbreviationEnum.CPO],
  });

export const EXTERNAL_CHARGING_STATIONS = () =>
  Link.static({
    nameLocaleKey: 'externalChargingStations',
    pathPart: 'external-charging-stations',
    parent: CHARGERS(),
    allowedPrivileges: [EXTERNALCHARGEPOINTREAD],
  });

export const CHARGING_STATION_MODELS = () =>
  Link.static({
    nameLocaleKey: 'models',
    pathPart: 'charging-station-models',
    parent: CHARGERS(),
    allowedPrivileges: [CHARGEPOINTMODELREAD],
  });

export const CONNECTOR_GROUPS = () =>
  Link.static({
    nameLocaleKey: 'connectorGroups',
    pathPart: 'connector-groups',
    parent: CHARGERS(),
    allowedPrivileges: [CONNECTORGROUPREAD],
  });

export const CHARGING_STATION_REPORTED_PROBLEMS = () =>
  Link.static({
    nameLocaleKey: 'reportedProblems',
    pathPart: 'reported-problems',
    parent: CHARGERS(),
    allowedPrivileges: [REPORTEDCHARGERPROBLEMREAD],
  });

export const CREATE_CHARGE_POINT = () =>
  Link.static({
    nameLocaleKey: 'chargingStationAdd',
    pathPart: 'create-charge-point',
    parent: CHARGERS(),
  });

export const CREATE_CHARGE_POINT_BULK = () =>
  Link.static({
    nameLocaleKey: 'chargingStationsAddMultiple',
    pathPart: 'create-charge-point-bulk',
    parent: CHARGERS(),
  });

export const CREATE_CHARGE_POINT_SITE = () =>
  Link.static({
    nameLocaleKey: 'locationAdd',
    pathPart: 'create-chargesite',
    parent: CHARGERS(),
  });

export const CREATE_CHARGING_STATION = () =>
  Link.static({
    nameLocaleKey: 'chargingStationAdd',
    pathPart: 'create-charging-station',
    parent: CHARGERS(),
  });

export const CREATE_CHARGING_STATION_BULK = () =>
  Link.static({
    nameLocaleKey: 'chargingStationsAddMultiple',
    pathPart: 'create-charging-station-bulk',
    parent: CHARGERS(),
  });

export const CREATE_CHARGING_STATION_BULK_UPLOAD = () =>
  Link.static({
    nameLocaleKey: 'chargingStationsUploadMultiple',
    pathPart: 'create-charging-station-bulk-upload',
    parent: CHARGERS(),
  });

export const CREATE_CHARGING_STATION_MODEL = () =>
  Link.static({
    nameLocaleKey: 'addModel',
    pathPart: `create`,
    parent: CHARGING_STATION_MODELS(),
  });

export const CREATE_CONNECTOR_GROUP = () =>
  Link.static({
    nameLocaleKey: 'connectorGroupCreate',
    pathPart: 'create-connector-group',
    parent: CONNECTOR_GROUPS(),
  });

export const CREATE_LOCATION = () =>
  Link.static({
    nameLocaleKey: 'locationAdd',
    pathPart: 'create-location',
    parent: CHARGERS(),
  });

export const CREATE_LOCATION_BULK = () =>
  Link.static({
    nameLocaleKey: 'locationAddCsv',
    pathPart: 'create-location-bulk',
    parent: CHARGERS(),
  });

export const EDIT_CHARGE_POINT_BULK = () =>
  Link.static({
    nameLocaleKey: 'chargingStationsEditMultiple',
    pathPart: 'edit-charge-point-bulk',
    parent: CHARGERS(),
  });

export const EDIT_CHARGING_STATION_BULK = () =>
  Link.static({
    nameLocaleKey: 'chargingStationsEditMultiple',
    pathPart: 'edit-charging-station-bulk',
    parent: CHARGERS(),
  });

export const InternalLocationTabName = 'internal' as const;

export const ExternalLocationTabName = 'external' as const;

type LocationsQueryParams =
  | { tab: typeof InternalLocationTabName; queryParams?: Partial<InternalLocationListFilterSettings> }
  | { tab: typeof ExternalLocationTabName; queryParams?: Partial<ExternalLocationListFilterSettings> };

export const LOCATIONS_BASE = (options?: LocationsQueryParams): StaticLinkProps => {
  const pathPart =
    options === undefined
      ? 'locations'
      : `locations?${stringify(
          Object.entries(options.queryParams ?? {}).reduce(
            (acc, [k, v]) => ({ ...acc, [[options.tab, k].join('.')]: v }),
            { tab: options.tab },
          ),
        )}`;

  return {
    nameLocaleKey: 'locations',
    pathPart,
    parent: CHARGERS(),
    allowedPrivileges: [CHARGEPOINTSITEREAD, EXTERNALCHARGEPOINTREAD],
  };
};

export const EXTERNAL_LOCATIONS_BASE = (): StaticLinkProps => ({
  nameLocaleKey: 'locations',
  pathPart: 'external-locations',
  parent: CHARGERS(),
  allowedPrivileges: [EXTERNALLOCATIONREAD],
});

export const EXTERNAL_LOCATIONS = () => Link.static(EXTERNAL_LOCATIONS_BASE());

export const LOCATIONS = (queryParams?: LocationsQueryParams) => Link.static(LOCATIONS_BASE(queryParams));

export const chargePointModelLink = ({ id, name }: DynamicLinkProps): Link =>
  new Link({
    name,
    pathPart: id,
    parent: CHARGE_POINT_MODELS(),
  });

export const chargepointLink = ({ id, name }: DynamicLinkPropsV2, chargeSiteLink: Link): Link =>
  new Link({
    name: name || '',
    pathPart: id,
    parent: new Link({
      name: CHARGE_POINTS().name,
      pathPart: CHARGE_POINTS().pathPart,
      ignoreInBreadcrumbs: true,
      parent: chargeSiteLink,
    }),
  });

export const chargepointPath = (id: string, chargePointSiteId: string) =>
  `${chargepointLink({ id }, chargesiteLink({ id: chargePointSiteId })).path}/`;

export const chargesiteLink = ({ id, name }: DynamicLinkPropsV2): Link =>
  new Link({
    name: name || '',
    pathPart: id,
    parent: new Link({ ...CHARGE_POINT_SITES_BASE(), ignoreInBreadcrumbs: true }),
  });

export const chargesitePath = (id: string) => `${chargesiteLink({ id, name: '' }).path}/`;

export const chargingStationLink = (chargingStation?: DynamicLinkPropsV2, location?: DynamicLinkPropsV2) =>
  new Link({
    name: chargingStation?.name || '',
    pathPart: chargingStation?.id || ':chargingStationId',
    parent: new Link({
      name: CHARGING_STATIONS().name,
      pathPart: CHARGING_STATIONS().pathPart,
      ignoreInBreadcrumbs: true,
      parent: locationsLink(location),
    }),
  });

export const externalChargingStationLink = (
  externalChargingStation?: DynamicLinkPropsV2,
  location?: DynamicLinkPropsV2,
) =>
  new Link({
    name: externalChargingStation?.name || '',
    pathPart: externalChargingStation?.id || ':externalChargingStationId',
    parent: new Link({
      name: EXTERNAL_CHARGING_STATIONS().name,
      pathPart: EXTERNAL_CHARGING_STATIONS().pathPart,
      ignoreInBreadcrumbs: true,
      parent: externalLocationsLink(location),
    }),
  });

export const chargingStationModelLink = ({ id, name }: DynamicLinkProps): Link =>
  new Link({
    name,
    pathPart: id,
    parent: CHARGING_STATION_MODELS(),
  });

export const chargingstationPath = (id: string, chargePointSiteId: string) =>
  `${chargingStationLink({ id }, { id: chargePointSiteId }).path}/`;

export const configurationKeysLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'configurationKeys',
    pathPart: 'configuration-keys',
    parent,
  });

export const connectorGroupLink = ({ id, name } = dynamicLinkParams): Link =>
  new Link({
    name,
    pathPart: id,
    parent: CONNECTOR_GROUPS(),
  });

export const connectorLink = ({ id, name }: DynamicLinkProps): Link =>
  new Link({
    name,
    pathPart: id,
    parent: CHARGE_POINTS(),
  });

export const connectorsPriceLink = (chargingStation: DynamicLinkPropsV2, location: DynamicLinkPropsV2) =>
  Link.static({
    nameLocaleKey: 'price',
    pathPart: 'prices',
    parent: chargingStationLink(chargingStation, location),
    allowedPrivileges: [CONNECTORPRICEREAD],
  });

export const createConnectorsPriceLink = (chargingStation: DynamicLinkPropsV2, location: DynamicLinkPropsV2) =>
  Link.static({
    nameLocaleKey: 'addPriceProfileAssociation',
    pathPart: 'add-price-profile-association',
    parent: connectorsPriceLink(chargingStation, location),
    allowedPrivileges: [CONNECTORPRICECREATE],
  });

export const eventLogLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'eventLog',
    pathPart: 'event-log',
    parent,
    allowedPrivileges: [CHARGEPOINTLOGREAD],
  });

export const firmwareLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'firmware',
    pathPart: 'firmware',
    parent,
    allowedPrivileges: [CHARGEPOINTFIRMWAREREAD],
  });

export const loadBalancingCreationLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'loadBalancingGroupAdd',
    pathPart: 'create-load-balancing-group',
    parent,
    allowedPrivileges: [SMARTCHARGINGCREATE],
  });

export const locationPath = (id: string) => `${locationsLink({ id, name: '' }).path}/`;

export const locationsLink = (location?: DynamicLinkPropsV2): Link =>
  new Link({
    name: location?.name || '',
    pathPart: location?.id || ':locationId',
    parent: LOCATIONS(),
  });

export const externalLocationsLink = (externalLocation?: DynamicLinkPropsV2): Link =>
  new Link({
    name: externalLocation?.name || '',
    pathPart: externalLocation?.id || ':locationId',
    parent: EXTERNAL_LOCATIONS(),
  });

export const ocppLogLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'ocppLog',
    pathPart: 'ocpp-log',
    parent,
    allowedPrivileges: [CHARGEPOINTLOGREAD],
  });

export const settingsAndServicesLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'settingsAndServices',
    pathPart: 'settings-and-services',
    parent,
  });

export const locationUtilisation = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'locationUtilisation',
    pathPart: 'utilization',
    parent,
  });

export const troubleshootingLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'troubleshooting',
    pathPart: 'troubleshooting',
    parent,
    allowedPrivileges: [CHARGEPOINTCOMMANDGETDIAGNOSTICS],
  });

export const reportedProblemsLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'reportedProblems',
    pathPart: 'reported-problems',
    parent,
    allowedPrivileges: [REPORTEDCHARGERPROBLEMREAD],
  });
