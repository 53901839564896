// md5:34965f6f09bc285144fafdabe731c7c9
import { emptySplitApi as api } from 'services/emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    searchConnectorGroupsUsingGet: build.query<
      SearchConnectorGroupsUsingGetApiResponse,
      SearchConnectorGroupsUsingGetApiArg
    >({
      query: queryArg => ({
        url: `/v2/connector-groups`,
        params: {
          organizationId: queryArg.organizationId,
          groupName: queryArg.groupName,
          fetchPrevious: queryArg.fetchPrevious,
          limit: queryArg.limit,
          includeGroupsFromNetwork: queryArg.includeGroupsFromNetwork,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
        },
      }),
    }),
    createConnectorGroupUsingPost: build.mutation<
      CreateConnectorGroupUsingPostApiResponse,
      CreateConnectorGroupUsingPostApiArg
    >({
      query: queryArg => ({ url: `/v2/connector-groups`, method: 'POST', body: queryArg.connectorGroupViewData }),
    }),
    featuresUsingGet: build.query<FeaturesUsingGetApiResponse, FeaturesUsingGetApiArg>({
      query: queryArg => ({
        url: `/v2/connector-groups/features`,
        params: { organizationId: queryArg.organizationId },
      }),
    }),
    findConnectorGroupUsingGet: build.query<FindConnectorGroupUsingGetApiResponse, FindConnectorGroupUsingGetApiArg>({
      query: queryArg => ({ url: `/v2/connector-groups/${queryArg.connectorGroupId}` }),
    }),
    updateConnectorGroupUsingPut: build.mutation<
      UpdateConnectorGroupUsingPutApiResponse,
      UpdateConnectorGroupUsingPutApiArg
    >({
      query: queryArg => ({
        url: `/v2/connector-groups/${queryArg.connectorGroupId}`,
        method: 'PUT',
        body: queryArg.connectorGroupView,
      }),
    }),
    deleteConnectorGroupUsingDelete: build.mutation<
      DeleteConnectorGroupUsingDeleteApiResponse,
      DeleteConnectorGroupUsingDeleteApiArg
    >({
      query: queryArg => ({ url: `/v2/connector-groups/${queryArg.connectorGroupId}`, method: 'DELETE' }),
    }),
    addUserGroupToConnectorGroupUsingPost: build.mutation<
      AddUserGroupToConnectorGroupUsingPostApiResponse,
      AddUserGroupToConnectorGroupUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v2/connector-groups/${queryArg.connectorGroupId}/user-groups/${queryArg.userGroupId}`,
        method: 'POST',
      }),
    }),
    removeUserGroupFromConnectorGroupUsingDelete: build.mutation<
      RemoveUserGroupFromConnectorGroupUsingDeleteApiResponse,
      RemoveUserGroupFromConnectorGroupUsingDeleteApiArg
    >({
      query: queryArg => ({
        url: `/v2/connector-groups/${queryArg.connectorGroupId}/user-groups/${queryArg.userGroupId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v2ConnectorGroupsApi };
export type SearchConnectorGroupsUsingGetApiResponse = /** status 200 OK */ PageResponseConnectorGroupView;
export type SearchConnectorGroupsUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
  /** groupName */
  groupName?: string;
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** limit */
  limit?: number;
  /** includeGroupsFromNetwork */
  includeGroupsFromNetwork?: boolean;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
};
export type CreateConnectorGroupUsingPostApiResponse = /** status 200 OK */ ConnectorGroupView;
export type CreateConnectorGroupUsingPostApiArg = {
  /** connectorGroup */
  connectorGroupViewData: ConnectorGroupViewData;
};
export type FeaturesUsingGetApiResponse = /** status 200 OK */ ConnectorGroupFeatures;
export type FeaturesUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
};
export type FindConnectorGroupUsingGetApiResponse = /** status 200 OK */ ConnectorGroupResponse;
export type FindConnectorGroupUsingGetApiArg = {
  /** connectorGroupId */
  connectorGroupId: string;
};
export type UpdateConnectorGroupUsingPutApiResponse = /** status 200 OK */ ConnectorGroupView;
export type UpdateConnectorGroupUsingPutApiArg = {
  /** connectorGroupId */
  connectorGroupId: string;
  /** connectorGroup */
  connectorGroupView: ConnectorGroupView;
};
export type DeleteConnectorGroupUsingDeleteApiResponse = /** status 200 OK */ void;
export type DeleteConnectorGroupUsingDeleteApiArg = {
  /** connectorGroupId */
  connectorGroupId: string;
};
export type AddUserGroupToConnectorGroupUsingPostApiResponse = /** status 200 OK */ ConnectorGroupResponse;
export type AddUserGroupToConnectorGroupUsingPostApiArg = {
  /** connectorGroupId */
  connectorGroupId: string;
  /** userGroupId */
  userGroupId: string;
};
export type RemoveUserGroupFromConnectorGroupUsingDeleteApiResponse = /** status 200 OK */ void;
export type RemoveUserGroupFromConnectorGroupUsingDeleteApiArg = {
  /** connectorGroupId */
  connectorGroupId: string;
  /** userGroupId */
  userGroupId: string;
};
export type ConnectorGroupViewData = {
  groupDescription?: string;
  groupName: string;
  nfcSessionIdCardPrefix?: string;
  operatorOrganizationId: string;
  reservationCapable: boolean;
  revenueToAo: boolean;
};
export type ViewMetadata = {
  id: string;
  version: number;
  created?: number;
  updated?: number;
};
export type ConnectorGroupView = {
  data: ConnectorGroupViewData;
  metadata: ViewMetadata;
};
export type AbstractPagingCursor = {
  idField: string;
  idFieldCursor?: object;
  idFieldSortOrder: string;
  sortField: string;
  sortFieldCursor?: object;
  sortFieldSortOrder: string;
};
export type Pagination = {
  next?: (any | null) | AbstractPagingCursor;
  prev?: (any | null) | AbstractPagingCursor;
};
export type PageResponseConnectorGroupView = {
  content: ConnectorGroupView[];
  pagination: Pagination;
};
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export type ConnectorGroupFeatures = {
  features: {
    [key: string]: boolean;
  };
};
export type OrganizationBase = {
  id: string;
  name: string;
};
export type UserGroup = {
  allowedMemberDurationInDays: number;
  approvedAssetOwner: OrganizationBase;
  /** The date that this entity was created, formatted as epoch (ms) */
  created: number;
  description: string;
  /** The model id */
  id: string;
  membersCount: number;
  name: string;
  nextGroupId: string;
  organization: OrganizationBase;
  /** The date that this entity was last modified, formatted as epoch (ms) */
  updated: number;
  version: number;
};
export type ConnectorGroupResponse = {
  connectorGroup: ConnectorGroupView;
  revenueToAoCapable: boolean;
  setNfcPrefixCapable: boolean;
  userGroups?: UserGroup[];
};
export const {
  useSearchConnectorGroupsUsingGetQuery,
  useCreateConnectorGroupUsingPostMutation,
  useFeaturesUsingGetQuery,
  useFindConnectorGroupUsingGetQuery,
  useUpdateConnectorGroupUsingPutMutation,
  useDeleteConnectorGroupUsingDeleteMutation,
  useAddUserGroupToConnectorGroupUsingPostMutation,
  useRemoveUserGroupFromConnectorGroupUsingDeleteMutation,
} = injectedRtkApi;
