import { ConnectionProfileEntity } from '@plugsurfing/cdm-api-client';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const fetchByOrganization = actionCreator.async<string | undefined, ConnectionProfileEntity[], Error>(
  'CONNECTION_PROFILES_FETCH',
);

export const fetch = actionCreator.async<string, ConnectionProfileEntity, Error>('CONNECTION_PROFILE_FETCH');

export const remove = actionCreator.async<string, Response, Error>('CONNECTION_PROFILES_REMOVE');
