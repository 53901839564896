// md5:4ef451dcb0f5305dbeb4ce20edc612b1
import { emptySplitApi as api } from 'services/emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getUserGroupsByOrganizationIdUsingGet: build.query<
      GetUserGroupsByOrganizationIdUsingGetApiResponse,
      GetUserGroupsByOrganizationIdUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v1/user-groups`, params: { organizationId: queryArg.organizationId } }),
    }),
    createUserGroupUsingPost: build.mutation<CreateUserGroupUsingPostApiResponse, CreateUserGroupUsingPostApiArg>({
      query: queryArg => ({ url: `/v1/user-groups`, method: 'POST', body: queryArg.userGroupCreateRequest }),
    }),
    exportMembersUsingPost: build.mutation<ExportMembersUsingPostApiResponse, ExportMembersUsingPostApiArg>({
      query: queryArg => ({
        url: `/v1/user-groups/exports/reports`,
        method: 'POST',
        body: queryArg.userGroupMembersReportRequest,
      }),
    }),
    getUserGroupByIdUsingGet: build.query<GetUserGroupByIdUsingGetApiResponse, GetUserGroupByIdUsingGetApiArg>({
      query: queryArg => ({ url: `/v1/user-groups/${queryArg.userGroupId}` }),
    }),
    updateUserGroupUsingPut: build.mutation<UpdateUserGroupUsingPutApiResponse, UpdateUserGroupUsingPutApiArg>({
      query: queryArg => ({
        url: `/v1/user-groups/${queryArg.userGroupId}`,
        method: 'PUT',
        body: queryArg.userGroupUpdateRequest,
      }),
    }),
    deleteUserGroupUsingDelete: build.mutation<DeleteUserGroupUsingDeleteApiResponse, DeleteUserGroupUsingDeleteApiArg>(
      {
        query: queryArg => ({ url: `/v1/user-groups/${queryArg.userGroupId}`, method: 'DELETE' }),
      },
    ),
    getOrganizationsInUserGroupUsingGet: build.query<
      GetOrganizationsInUserGroupUsingGetApiResponse,
      GetOrganizationsInUserGroupUsingGetApiArg
    >({
      query: queryArg => ({
        url: `/v1/user-groups/${queryArg.userGroupId}/organizations`,
        params: {
          fetchPrevious: queryArg.fetchPrevious,
          limit: queryArg.limit,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
        },
      }),
    }),
    addOrganizationsUsingPost: build.mutation<AddOrganizationsUsingPostApiResponse, AddOrganizationsUsingPostApiArg>({
      query: queryArg => ({
        url: `/v1/user-groups/${queryArg.userGroupId}/organizations`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    removeOrganizationsUsingDelete: build.mutation<
      RemoveOrganizationsUsingDeleteApiResponse,
      RemoveOrganizationsUsingDeleteApiArg
    >({
      query: queryArg => ({
        url: `/v1/user-groups/${queryArg.userGroupId}/organizations/${queryArg.organizationIds}`,
        method: 'DELETE',
      }),
    }),
    addUsersUsingPost: build.mutation<AddUsersUsingPostApiResponse, AddUsersUsingPostApiArg>({
      query: queryArg => ({
        url: `/v1/user-groups/${queryArg.userGroupId}/users`,
        method: 'POST',
        body: queryArg.userGroupAddUserRequest,
      }),
    }),
    removeUsersUsingDelete: build.mutation<RemoveUsersUsingDeleteApiResponse, RemoveUsersUsingDeleteApiArg>({
      query: queryArg => ({
        url: `/v1/user-groups/${queryArg.userGroupId}/users`,
        method: 'DELETE',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v1UserGroupsApi };
export type GetUserGroupsByOrganizationIdUsingGetApiResponse = /** status 200 OK */ UserGroup[];
export type GetUserGroupsByOrganizationIdUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
};
export type CreateUserGroupUsingPostApiResponse = /** status 200 OK */ UserGroup;
export type CreateUserGroupUsingPostApiArg = {
  /** request */
  userGroupCreateRequest: UserGroupCreateRequest;
};
export type ExportMembersUsingPostApiResponse = /** status 200 OK */ void;
export type ExportMembersUsingPostApiArg = {
  /** reportRequest */
  userGroupMembersReportRequest: UserGroupMembersReportRequest;
};
export type GetUserGroupByIdUsingGetApiResponse = /** status 200 OK */ UserGroup;
export type GetUserGroupByIdUsingGetApiArg = {
  /** userGroupId */
  userGroupId: string;
};
export type UpdateUserGroupUsingPutApiResponse = /** status 200 OK */ UserGroup;
export type UpdateUserGroupUsingPutApiArg = {
  /** userGroupId */
  userGroupId: string;
  /** request */
  userGroupUpdateRequest: UserGroupUpdateRequest;
};
export type DeleteUserGroupUsingDeleteApiResponse = /** status 200 OK */ void;
export type DeleteUserGroupUsingDeleteApiArg = {
  /** userGroupId */
  userGroupId: string;
};
export type GetOrganizationsInUserGroupUsingGetApiResponse = /** status 200 OK */ PageResponseEsOrganization;
export type GetOrganizationsInUserGroupUsingGetApiArg = {
  /** userGroupId */
  userGroupId: string;
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** limit */
  limit?: number;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
};
export type AddOrganizationsUsingPostApiResponse = /** status 200 OK */ void;
export type AddOrganizationsUsingPostApiArg = {
  /** userGroupId */
  userGroupId: string;
  /** organizationIds */
  body: string[];
};
export type RemoveOrganizationsUsingDeleteApiResponse = /** status 200 OK */ void;
export type RemoveOrganizationsUsingDeleteApiArg = {
  /** userGroupId */
  userGroupId: string;
  /** organizationIds */
  organizationIds: string;
};
export type AddUsersUsingPostApiResponse = /** status 200 OK */ void;
export type AddUsersUsingPostApiArg = {
  /** userGroupId */
  userGroupId: string;
  /** userGroupAddUserRequest */
  userGroupAddUserRequest: UserGroupAddUserRequest;
};
export type RemoveUsersUsingDeleteApiResponse = /** status 200 OK */ void;
export type RemoveUsersUsingDeleteApiArg = {
  /** userGroupId */
  userGroupId: string;
  /** userIds */
  body: string[];
};
export type OrganizationBase = {
  id: string;
  name: string;
};
export type UserGroup = {
  allowedMemberDurationInDays: number;
  approvedAssetOwner: OrganizationBase;
  /** The date that this entity was created, formatted as epoch (ms) */
  created: number;
  description: string;
  /** The model id */
  id: string;
  membersCount: number;
  name: string;
  nextGroupId: string;
  organization: OrganizationBase;
  /** The date that this entity was last modified, formatted as epoch (ms) */
  updated: number;
  version: number;
};
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export type UserGroupCreateRequest = {
  allowedMemberDurationInDays?: number;
  approvedAssetOwnerId?: string;
  description?: string;
  name: string;
  nextGroupId?: string;
  organizationId: string;
};
export type UserGroupMembersReportRequest = {
  /** Organization id to base the report on */
  organizationId?: string;
  /** Email to send the report to */
  recipientEmail?: string;
  /** User group id this report is for */
  userGroupId?: string;
};
export type UserGroupUpdateRequest = {
  allowedMemberDurationInDays?: number;
  approvedAssetOwnerId?: string;
  description?: string;
  name: string;
  nextGroupId?: string;
  version: number;
};
export type EsOrganization = {
  id?: string;
  name: string;
};
export type AbstractPagingCursor = {
  idField: string;
  idFieldCursor?: object;
  idFieldSortOrder: string;
  sortField: string;
  sortFieldCursor?: object;
  sortFieldSortOrder: string;
};
export type Pagination = {
  next?: (any | null) | AbstractPagingCursor;
  prev?: (any | null) | AbstractPagingCursor;
};
export type PageResponseEsOrganization = {
  content: EsOrganization[];
  pagination: Pagination;
  totalItems: number;
};
export type UserGroupAddUserRequest = {
  email: string;
  userVirtualIds: string[];
};
export const {
  useGetUserGroupsByOrganizationIdUsingGetQuery,
  useCreateUserGroupUsingPostMutation,
  useExportMembersUsingPostMutation,
  useGetUserGroupByIdUsingGetQuery,
  useUpdateUserGroupUsingPutMutation,
  useDeleteUserGroupUsingDeleteMutation,
  useGetOrganizationsInUserGroupUsingGetQuery,
  useAddOrganizationsUsingPostMutation,
  useRemoveOrganizationsUsingDeleteMutation,
  useAddUsersUsingPostMutation,
  useRemoveUsersUsingDeleteMutation,
} = injectedRtkApi;
