import {
  Badge,
  Box,
  Flex,
  IconButton,
  Loader,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@plugsurfing/plugsurfing-design';
import groupBy from 'lodash/groupBy';
import { useState } from 'react';
import { InfoTab } from 'views/powerSearch/components/InfoTab';
import { Preview } from 'views/powerSearch/components/Preview';
import { ResultGroups } from 'views/powerSearch/components/ResultGroup';
import { Search } from 'views/powerSearch/components/Search';
import { usePowerSearch } from 'views/powerSearch/utils/PowerSearchContext';
import { useKeyDown } from 'views/powerSearch/utils/useKeyDown';

export const PowerSearch = () => {
  const { searchResult, isSearchResultLoading, hoveredEntity, toggleOpen, isOpen, setHoveredEntity } = usePowerSearch();

  const [tab, setTab] = useState<'search' | 'info'>('search');
  const groupedResults = groupBy(searchResult, item => item.type);

  useKeyDown('KeyB', toggleOpen.toggle);

  return (
    <Modal isOpen={isOpen} size="L" onClose={toggleOpen.off}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box alignItems="center">
            Power Search <Badge variant="info">ALPHA</Badge>
            <IconButton
              ml="component.2xs"
              aria-label="info-button"
              icon="Info"
              variant="tertiary"
              onClick={() => setTab('info')}
            />
          </Box>
        </ModalHeader>
        <ModalBody>
          {tab === 'search' && (
            <VStack spacing="component.s" gap={4}>
              <Search />
              <Flex width="100%" gap="component.s" onMouseLeave={() => setHoveredEntity(undefined)}>
                <Box flex={1}>
                  <ResultGroups resultGroups={groupedResults} />
                </Box>

                {hoveredEntity && (
                  <Box flex={1} flexWrap="wrap">
                    <Preview data={hoveredEntity} />
                  </Box>
                )}
              </Flex>
              {isSearchResultLoading && <Loader size="L" />}
            </VStack>
          )}
          {tab === 'info' && <InfoTab setTab={() => setTab('search')}></InfoTab>}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
