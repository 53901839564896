import { Box, Divider, Icon } from '@plugsurfing/plugsurfing-design';
import { ELEVATION } from 'components/design-elements/CdTable/anatomy/constants';
import { ColumnResizeHandler, useColumnResizer } from 'components/design-elements/CdTable/anatomy/useColumnResizer';
import { ExtraColumnOptions } from 'components/design-elements/CdTable/anatomy/useColumns';
import { MouseEvent, useMemo, useRef, type ForwardedRef } from 'react';
import { ColumnInstance } from 'react-table';
import { getStickyColumnProps } from './helpers';
import styles from './styles.module.scss';

interface CdTableHeaderColumnProps<T extends object> {
  column: ColumnInstance<T>;
  extraOptions?: ExtraColumnOptions;
  isLast: boolean;
  onResize?: ColumnResizeHandler<T>;
  onReset: () => void;
  onResizeEnd?: () => void;
  index: number;
  stickyColumnCount: number;
  stickyColumnRef: ForwardedRef<HTMLTableCellElement> | null;
}

export function CdTableHeaderColumn<T extends object>({
  column,
  extraOptions,
  isLast,
  onResize,
  onReset,
  onResizeEnd,
  index,
  stickyColumnCount,
  stickyColumnRef,
}: CdTableHeaderColumnProps<T>) {
  const { className, style, ...thProps } = column.getHeaderProps(column.getSortByToggleProps());
  const [resizerState, resizeHandleProps] = useColumnResizer(column, onResize, onReset, onResizeEnd);
  const resizerRef = useRef<HTMLDivElement>(null);

  const onClick = (thProps as any).onClick;
  const handleClick = useMemo(
    () =>
      onClick === undefined
        ? undefined
        : (e: MouseEvent<HTMLTableCellElement>) => {
            if (
              resizerRef.current == null ||
              !(e.target instanceof HTMLElement) ||
              !resizerRef.current.contains(e.target)
            ) {
              // Toggle sorting if the click target isn't resizer
              onClick(e);
            }
          },
    [onClick],
  );
  const isResizing = resizerState === 'resizing';
  const stickyAttrs = getStickyColumnProps(index, stickyColumnCount);

  return (
    <th
      {...thProps}
      {...extraOptions?.thProps}
      {...stickyAttrs}
      ref={stickyColumnRef}
      className={[
        className ?? '',
        styles.columnHeader,
        column.isSorted && column.canSort ? styles.isSortColumn : '',
        extraOptions?.tdProps?.className ?? '',
        extraOptions?.isActionCell ? styles.action : '',
        stickyAttrs.className ?? '',
      ].join(' ')}
      style={{
        ...style,
        position: undefined,
        ...stickyAttrs.style,
        paddingRight: extraOptions?.textAlign === 'right' || isLast ? undefined : 0,
        textAlign: extraOptions?.textAlign,
        ...(isResizing ? { pointerEvents: 'none' } : {}),
      }}
      aria-sort={column.isSortedDesc ? 'descending' : column.isSorted ? 'ascending' : 'none'}
      onClick={handleClick}
    >
      {extraOptions?.isActionCell ? (
        column.render('Header')
      ) : (
        <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {column.render('Header')}
          {column.isSortedDesc ? (
            <Icon ml="component.3xs" name="ArrowSortDown" size="s" verticalAlign="bottom" />
          ) : column.isSorted ? (
            <Icon ml="component.3xs" name="ArrowSortUp" size="s" verticalAlign="bottom" />
          ) : column.canSort ? (
            <Icon ml="component.3xs" name="ArrowSort" size="s" verticalAlign="bottom" />
          ) : undefined}
        </Box>
      )}
      {onResize !== undefined && column.canResize && !(extraOptions?.isActionCell ?? false) && (
        <Box
          ref={resizerRef}
          position="absolute"
          right="-1px"
          height="100%"
          top={0}
          p="component.xs"
          mr={isLast ? 0 : '-component.xs'}
          opacity={isResizing ? 100 : 0}
          sx={{ 'th:hover > &': { opacity: 100 } }}
          transition="opacity .2s ease"
          cursor="ew-resize"
          zIndex={ELEVATION.COLUMN_RESIZER}
          {...resizeHandleProps}
        >
          <Box
            w={resizerState === 'idle' ? 0 : '36px'}
            h={resizerState === 'idle' ? 0 : '36px'}
            bg="hover.primary"
            borderRadius="100%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            transition="opacity .2s ease, width .2s ease, height .2s ease"
            opacity={resizerState === 'idle' ? 0 : 100}
          />
          <Divider
            orientation="vertical"
            h="100%"
            borderColor={isResizing ? 'border.primary-hover' : undefined}
            sx={{ 'div:hover > &': { borderColor: 'border.primary-hover' } }}
            transition="border-color .2s ease"
          />
        </Box>
      )}
    </th>
  );
}
