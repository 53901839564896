import { ChargingKey } from '@plugsurfing/cdm-api-client';
import { Badge, Box, Heading } from '@plugsurfing/plugsurfing-design';
import { CdKeyValue } from 'components/design-elements';

export const ChargingKeyPreview = ({ chargingKey }: { chargingKey: ChargingKey }) => (
  <Box>
    <Heading size="xs" mb="component.s">
      Charging Key
    </Heading>
    <CdKeyValue label="User Email">{chargingKey.owner?.email}</CdKeyValue>
    <CdKeyValue label="Reference Id">{chargingKey.referenceId}</CdKeyValue>
    <CdKeyValue label="Contract Id">{chargingKey.contractId}</CdKeyValue>
    <CdKeyValue label="Type">{chargingKey.type?.name}</CdKeyValue>
    <CdKeyValue label="Enabled">
      <Badge variant={chargingKey.isEnabled ? 'positive' : 'error'}>{chargingKey.isEnabled ? 'Yes' : 'No'}</Badge>
    </CdKeyValue>
    <CdKeyValue label="Payment options">{chargingKey.assignedPaymentOptions?.map(option => option.name)}</CdKeyValue>
  </Box>
);
