import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalProps } from '@plugsurfing/plugsurfing-design';
import { type ReactNode } from 'react';

export interface EditModalProps extends Omit<ModalProps, 'children'> {
  modalBody: ReactNode;
  title: string;
}

const EditModal = ({ isOpen = true, title, modalBody, ...modalProps }: EditModalProps) => {
  return (
    <Modal isOpen={isOpen} size="M" {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader zIndex={999}>{title}</ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
