import { OrgRegistrationOrderResponse } from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export interface State {
  byId: Record<string, OrgRegistrationOrderResponse>;
}

export const initialState: State = { byId: {} };

export default function (state: State = initialState, action: Action): State {
  return produce(state, draft => {
    if (isType(action, actions.fetchOrganizationOrder.done)) {
      const { result, params } = action.payload;

      draft.byId[params] = result;
    }
  });
}
