import { Box, BoxProps, Flex, FlexProps, Heading } from '@plugsurfing/plugsurfing-design';
import { CdTooltip } from 'components/design-elements';
import { memo, ReactNode } from 'react';

declare type HeaderSize = 'small' | 'medium' | 'large';

export interface CdPageSectionProps extends BoxProps {
  header?: string | JSX.Element;
  headerSize?: HeaderSize;
  topLeft?: ReactNode;
  topRight?: ReactNode;
  toolTip?: string | JSX.Element;
  required?: boolean;
  headerPropsOverrides?: Partial<FlexProps>;
}

const headerTypes = {
  small: 'h4',
  medium: 'h3',
  large: 'h2',
} as const;

const headerSizes = {
  small: '3xs',
  medium: '3xs',
  large: '2xs',
} as const;

const CdPageSection = memo(
  ({
    header,
    headerSize = 'large',
    topRight,
    topLeft,
    toolTip,
    children,
    required = false,
    headerPropsOverrides,
    ...rest
  }: CdPageSectionProps) => {
    return (
      <Box as="section" pb="component.l" {...rest}>
        {(topLeft || topRight || header) && (
          <Flex
            gap="component.s"
            justifyContent="space-between"
            mb="component.2xs"
            alignItems="end"
            {...headerPropsOverrides}
          >
            {topLeft}
            {header && (
              <Flex alignItems="center">
                <Heading
                  as={headerTypes[headerSize]}
                  size={headerSizes[headerSize]}
                  color={required ? 'interactive.danger' : 'inherit'}
                  _after={
                    required
                      ? {
                          content: "'*'",
                          paddingX: '0.2em',
                        }
                      : {}
                  }
                >
                  {header}
                </Heading>
                {toolTip !== undefined && <CdTooltip label={toolTip} iconProps={{ ml: 'component.3xs' }} />}
              </Flex>
            )}
            <Box flexGrow={1} /> {/* This element positions the other elements properly in the flexbox */}
            {topRight}
          </Flex>
        )}
        {children}
      </Box>
    );
  },
);

export default CdPageSection;
